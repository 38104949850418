import { Typography, Box } from "@mui/material";
import DashboardOverview from "./DashboardOverview";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";

const DashboardPage = () => {
    const isLargeScreen = useIsLargeScreen();

    return (
        <Box sx={ isLargeScreen ? { margin: 3 } : { marginY: 2 }}>
            <Typography sx={!isLargeScreen ? { marginX: 1 } : { marginX: 0 }} variant="h4" gutterBottom>Dashboard Overview</Typography>
            <DashboardOverview />
        </Box>
    );
};

export default DashboardPage;