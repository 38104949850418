import { Autocomplete, TextField, Chip, Typography, Box } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import HelpIcon from "./HelpIcon";

type FormChipInputProps = {
    label?: string
    placeholder: string
    name: string
    required?: boolean
    title?: string
    tooltip?: string
}

const FormChipInput = ({
    label,
    placeholder,
    name,
    required,
    title,
    tooltip
}: FormChipInputProps) => {
    const { control, formState: { errors, isValidating } } = useFormContext();

    const currentErrors: ({ message: string } | undefined)[] = errors[name] || [];

    const lastErrorIndex = currentErrors.length - 1;
    const lastErrorMessage = lastErrorIndex >= 0 ? currentErrors[lastErrorIndex]?.message : undefined;
    const errorMessage = lastErrorMessage || errors?.[name]?.message;

    return (
        <>
            {title &&
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 1 }}>
                    <Typography sx={{ fontWeight: 700, color: "black" }} >
                        {title}
                    </Typography>
                    {tooltip &&
                        <HelpIcon title={tooltip} />
                    }
                </Box>
            }
            <Controller
                name={name}
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
                    <Autocomplete
                        multiple
                        options={[]}
                        defaultValue={[]}
                        value={value}
                        onBlur={onBlur}
                        freeSolo
                        isOptionEqualToValue={() => false}
                        onChange={(e, data) => onChange(data)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                                return (
                                    <Chip
                                        {...getTagProps({ index })}
                                        variant="outlined"
                                        key={index}
                                        label={option}
                                        tabIndex={0}
                                        disabled={isValidating}
                                        color={lastErrorIndex === index ? "error" : "secondary"}
                                    />
                                );
                            }
                            )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={value}
                                name={name}
                                label={label}
                                placeholder={placeholder}
                                error={!isValidating && !!error}
                                helperText={!isValidating ? errorMessage : "Validating..."}
                                required={required}
                            />
                        )}
                    />
                )
                }
            />
        </>
    );
};

export default FormChipInput;