export type ErrorListProps = {
    errors: string[]
};

const ErrorList = ({ errors }: ErrorListProps) => {
    return (
        <>
            {errors.map((errorMessage, index) => (
                <p className="text-error mt-2 ml-2" key={index + errorMessage}>{errorMessage}</p>
            ))}
        </>
    );
};

export default ErrorList;