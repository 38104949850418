import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { tomorrowAtMidnight } from "../../utils/dateFormatters";

export type TalentEditFormValues = {
    organizationAvailability?: string | null
    agencyId?: string | null
    isQualiopiRequired: "true" | "false"
    vendorName?: string | null
    presentationStyle?: string | null
    contractSentDate?: Date | null
    insuranceExpiryDate?: Date | null
    ratePackId?: string
    competencyLevelId?: string
    wheelPositionId?: string | null
    documentationLanguageId?: string | null
    knownCompetitors?: string | null
    notes?: string | null
    rlc?: string | null
    professionalBackground?: string | null
    locationId: string | null
    localityId: string | null
};

export const useEditTalentForm = (initialValues?: TalentEditFormValues) => {
    const schema = talentValidationSchema();

    return useForm<TalentEditFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            ...initialValues,
            contractSentDate: initialValues?.contractSentDate || null,
            insuranceExpiryDate: initialValues?.insuranceExpiryDate || null,
            locationId: initialValues?.locationId ?? "",
            localityId: initialValues?.localityId ?? ""
        }
    });
};

const talentValidationSchema = () => {
    return yup
        .object({
            organizationAvailability: yup.string().max(40, "Availability cannot be longer than 40 characters.").nullable(),
            agencyId: yup.string().nullable(),
            isQualiopiRequired: yup.string().required(),
            vendorName: yup.string().nullable(),
            presentationStyle: yup.string().nullable(),
            contractSentDate: yup.date()
                .max(new Date(), "Contract sent date must be today or earlier.")
                .typeError("Contract sent date must be a valid date.")
                .nullable(),
            insuranceExpiryDate: yup.date()
                .min(tomorrowAtMidnight(), "Expiry date must be later than today.")
                .typeError("Expiry date must be a valid date.")
                .nullable(),
            ratePackId: yup
                .string()
                .required("Rate pack is required."),
            competencyLevelId: yup.string().required("Competency level is required."),
            wheelPositionId: yup.string().nullable(),
            documentationLanguageId: yup.string().nullable(),
            knownCompetitors: yup.string().max(500, "Known competitors cannot be longer than 500 characters.").nullable(),
            notes: yup.string().max(2500, "Notes cannot be longer than 2500 characters.").nullable(),
            rlc: yup.string().max(100, "RLC cannot be longer than 100 characters.").nullable(),
            professionalBackground: yup.string().max(5000, "RLC cannot be longer than 5000 characters.").nullable(),
            locationId: yup.string().required("Country is required."),
            localityId: yup.string().required("City is required."),
        })
        .required();
};