import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "../common/giggedApiSWR";
import { ApiClientStatisticsDto } from "../models/dashboardStatistics/clientStatistics";

export type UseClientStatisticsReturn =
    UseGiggedApiSWRReturn<ApiClientStatisticsDto> &
    {
        statistics?: ApiClientStatisticsDto
        isLoading: boolean
    }

export const useClientStatistics = (): UseClientStatisticsReturn => {

    const url = "api/admin-dashboard/statistics/clients";

    const apiCall = useGiggedApiSWR<ApiClientStatisticsDto>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        statistics: apiCall.data,
        isLoading: apiCall.isLoading
    };
};