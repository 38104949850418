import { Box, Tooltip, Button } from "@mui/material";
import useIsLargeScreen from "../hooks/useIsLargeScreen";

export type EditButton = {
    disabled: boolean
    buttonText: string
    disabledTooltip?: string
    onClick: () => void
}

const EditButton = ({
    disabled,
    buttonText,
    disabledTooltip,
    onClick
}: EditButton) => {
    const isLargeScreen = useIsLargeScreen();
    return (
        <Box>
            {disabledTooltip && disabled ? (
                <Tooltip enterDelay={10} title={disabledTooltip} placement="top" style={{ backgroundColor: "transparent" }}>
                    <span className="!cursor-not-allowed">
                        <Button
                            disabled={disabled}
                            color="secondary"
                            variant="text"
                            component="div"
                            onClick={onClick}
                            aria-label="edit"
                            sx={{
                                "&.Mui-disabled": {
                                    pointerEvents: "none",
                                },
                            }}
                        >
                            {buttonText}
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button 
                    disabled={disabled} 
                    color="secondary" 
                    variant="outlined"
                    className="w-full md:w-inherit"
                    onClick={onClick}
                    sx={!isLargeScreen ? { marginTop: 2 } : { marginTop: 0 }}
                >
                    {buttonText}
                </Button>
            )}
        </Box>
    );
};

export default EditButton;