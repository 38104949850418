import { Box, Typography } from "@mui/material";
import HelpIcon from "../HelpIcon";

export type DashboardSubSectionProps = {
    title: string
    tooltip?: string
    children: React.ReactNode
}

const DashboardSubSection = ({ children, title, tooltip }: DashboardSubSectionProps) => {
    return (
        <Box>
            <div className="flex flex-row items-center">
                <Typography component="h4" variant="subtitle1">{title}</Typography>
                {tooltip && <HelpIcon title={tooltip} />}
            </div>
            <div className="space-y-6 md:space-y-0 md:flex md:space-x-4 md:child:grow overflow-hidden overflow-x-scroll ">
                {children}
            </div>
        </Box>
    );
};

export default DashboardSubSection;