import clsx from "clsx";
import { useState } from "react";

export type FileDropZoneProps = {
    inputId: string
    onFileDrop: (file: File) => void
    disabled?: boolean
    className?: string
}

const FileDropZone = ({
    inputId,
    onFileDrop,
    disabled,
    className
}: FileDropZoneProps) => {
    const [dragActive, setDragActive] = useState(false);

    const handleDrag: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            onFileDrop(event.dataTransfer.files[0]);
        }
    };

    const handleFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.target?.files && event.target.files[0];

        if (!file) return;

        onFileDrop(file);
    };

    return (
        <div
            className={clsx(
                "relative border-2 border-dashed rounded-lg p-5 mb-4",
                dragActive && "bg-btn-secondary-bg-hover",
                className,
            )}
            onDragEnter={handleDrag}
        >
            <p className="mb-4 text-center"> Drop files here to upload </p>
            <input id={inputId} className="hidden" type="file" onChange={handleFileInputChange} />
            <div className="flex justify-center">
                <label
                    htmlFor={inputId}
                    role="button"
                    className={clsx(
                        "cursor-pointer text-primary-text font-bold py-[0.75rem] px-[1.5rem] rounded-full disabled:text-disabled-text focus:outline whitespace-nowrap",
                        !disabled && "border-2 border-primary-text hover:bg-btn-secondary-bg-hover focus:outline-1",
                        disabled && " border-2 border-disabled",
                    )}
                >
                    Select file
                </label>
            </div>
            {dragActive && (
                <div className="absolute rounded-lg inset-0" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop} />
            )}
        </div>
    );
};

export default FileDropZone;
