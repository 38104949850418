import { useNavigate } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { BadgeCreateFormValues } from "../components/BadgeCreateForm";
import BadgeCreateForm from "../components/BadgeCreateForm/BadgeCreateForm";
import { useBadges } from "../api/badges";

const BadgeCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createBadge, isCreatingBadge } = useBadges();

    const onSubmit = async (values: BadgeCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createBadge({
            ...values,
        });

        if (response.success) {
            successToast("Badge successfully created.");
            navigate(appPaths.badges.index);
        }


        return response;
    };

    return (
        <FormContainer>
            <BadgeCreateForm onSubmit={onSubmit} isSubmitting={isCreatingBadge} />
        </FormContainer>
    );
};

export default BadgeCreatePage;
