import { useState } from "react";
import { Button } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { ResendInviteClientUserDto } from "../api/models/client";

export type ResendInviteButtonProps = {
    email: string
    onResend: (dto: ResendInviteClientUserDto) => Promise<GigApiFetcherResponse<void>>
}

const ResendInviteButton = ({
    email,
    onResend
}: ResendInviteButtonProps) => {
    const [isResending, setIsResending] = useState(false);
  
    const handleClick = async () => {
        setIsResending(true);
        try {
            await onResend({email: email});
        } finally {
            setIsResending(false);
        }
    };
  
    return (
        <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={handleClick}
            sx={{ alignSelf: "flex-start" }}
            startIcon={<TelegramIcon sx={isResending ? { opacity: "26%" } : { opacity: "100%" }}/>}
            disabled={isResending}
        >
            {isResending ? "Resending..." : "Resend Invite"}
        </Button>
    );
};

export default ResendInviteButton;