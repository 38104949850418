import pluralizeString from "../utils/pluralizeString";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { AddLogoToOrganizationDto, OrganizationConfigDto, EditableOrganizationConfigFields } from "./models/organization";

export const useUpdateOrganizationConfig = () => useAuthenticatedGigApiFetcher<EditableOrganizationConfigFields, void, EditableOrganizationConfigFields>("PUT",
    (dto) => ({
        path: "api/organizations/config",
        body: dto
    }));

export const useAddLogoToOrganization = () => useAuthenticatedGigApiFetcher<AddLogoToOrganizationDto, void, { dto: AddLogoToOrganizationDto }>("POST",
    ({ dto }) => ({
        path: "api/organizations/logo",
        body: dto
    }));

export type UseOrganizationConfigReturn = {
    organizationConfig?: OrganizationConfigDto
    isLoading: boolean
    gigTerminology: string
    gigTerminologyPlural: string
    giggedClientTerminology: string
    giggedClientTerminologyPlural: string
    talentTerminology: string
    talentTerminologyPlural: string
    updateOrganizationConfig: (dto: EditableOrganizationConfigFields) => Promise<GigApiFetcherResponse<void>>
    addLogoToOrganization: (dto: AddLogoToOrganizationDto) => Promise<GigApiFetcherResponse<void>>
    isUpdatingOrganizationConfig: boolean
    isAddingLogoToOrganization: boolean
    gigDashboardDomain: string
}

export const useOrganizationConfig = (): UseOrganizationConfigReturn => {
    const url = "api/organizations/config";
    const apiCall = useGiggedApiSWR<OrganizationConfigDto>(url);

    const [updateOrganizationConfig, isUpdatingOrganizationConfig] = useUpdateOrganizationConfig();
    const [addLogoToOrganization, isAddingLogoToOrganization] = useAddLogoToOrganization();

    const gigTerminology = apiCall.data?.gigTerminology || "Gig";
    const giggedClientTerminology = apiCall.data?.giggedClientTerminology || "Client";
    const talentTerminology = apiCall.data?.talentTerminology || "Talent";

    return {
        organizationConfig: apiCall.data,
        isLoading: apiCall.isLoading,
        gigTerminology: gigTerminology,
        gigTerminologyPlural: pluralizeString(gigTerminology),
        giggedClientTerminology: giggedClientTerminology,
        giggedClientTerminologyPlural: pluralizeString(giggedClientTerminology),
        talentTerminology: talentTerminology,
        talentTerminologyPlural: pluralizeString(talentTerminology),
        updateOrganizationConfig: async (dto: EditableOrganizationConfigFields) => {
            const response = await updateOrganizationConfig(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        addLogoToOrganization: async (dto: AddLogoToOrganizationDto ) => {
            const response = await addLogoToOrganization({ dto });

            if(response.success) {
                apiCall.mutate();
            }

            return response;
        },
        isUpdatingOrganizationConfig,
        isAddingLogoToOrganization,
        gigDashboardDomain: apiCall.data?.gigDashboardDomain || "",
    };
};