import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "../common/giggedApiSWR";
import { ApiSkillStatisticsDto } from "../models/dashboardStatistics/skillStatistics";

export type UseSkillStatisticsReturn =
    UseGiggedApiSWRReturn<ApiSkillStatisticsDto> &
    {
        statistics?: ApiSkillStatisticsDto
        isLoading: boolean
    }

export const useSkillStatistics = (): UseSkillStatisticsReturn => {

    const url = "api/admin-dashboard/statistics/skills";

    const apiCall = useGiggedApiSWR<ApiSkillStatisticsDto>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        statistics: apiCall.data,
        isLoading: apiCall.isLoading
    };
};