import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { ApiReportDto } from "./models/api/reports";

export type UseReportsReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiReportDto>>, "data"> & {
        reports: ApiReportDto[]
        sortOrder:  SortOrder
        orderBy: string
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
    };

export const useReports = (initialPageSize = 10): UseReportsReturn => {
    const [orderBy, setOrderBy] = useState("CreatedAt");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const url = "api/reports?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&`;

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiReportDto>>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        reports: apiCall.data?.items || [],
        sortOrder,
        orderBy,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
    };
};