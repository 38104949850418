import { InputAdornment, TextField, Theme, SxProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export type SearchBoxProps = {
    searchTerm: string,
    onChange: (value: string) => void,
    placeholder: string,
    className?: string,
    sx?: SxProps<Theme>,
}

const SearchBox = ({
    searchTerm,
    onChange,
    placeholder,
    sx,
}: SearchBoxProps) => {
    return (
        <TextField
            sx={sx}
            placeholder={placeholder}
            value={searchTerm}
            onChange={event => onChange(event.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchBox;