import { useState } from "react";

export type UseConfirmDialogBoxStateReturn = [
    () => void,
    {
        isOpen: boolean,
        onCancel: () => void,
        onConfirm: () => void,
    }
];

const useConfirmDialogBoxState = (
    { onOpen, onConfirm, onCancel }:
    {
        onOpen?: () => void,
        onConfirm?: () => void,
        onCancel?: () => void,
    }): UseConfirmDialogBoxStateReturn => {

    const [isOpen, setIsOpen] = useState(false);

    const openDialog = () => {
        onOpen && onOpen();
        setIsOpen(true);
    };

    const handleConfirm = () => {
        onConfirm && onConfirm();
        setIsOpen(false);
    };

    const handleCancel = () => {
        onCancel && onCancel();
        setIsOpen(false);
    };

    return [
        openDialog,
        {
            isOpen,
            onConfirm: handleConfirm,
            onCancel: handleCancel,
        }
    ];
};

export default useConfirmDialogBoxState;