import { IconButton, TableCell, useTheme } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

import { useReports } from "../../api/reports";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import formatDateTimeString from "../../utils/formatDateTimeString";
import TableChipCell from "../Table/TableChipCell";
import { useOrganizationConfig } from "../../api/organization";
import { useAppPaths } from "../../Routes";

const ReportsTable = () => {
    const { 
        gigTerminology, 
        giggedClientTerminology, 
        talentTerminology 
    } = useOrganizationConfig();
    const reports = useReports();
    const theme = useTheme();
    const appPaths = useAppPaths();

    const handleSortOrderChange = (property: string) => {
        const isAsc = reports.orderBy === property && reports.sortOrder === "asc";
        reports.setSortOrder(isAsc ? "desc" : "asc");
        reports.setOrderBy(property);
    };

    if (reports.isLoading) return (
        <Loader />
    );

    const getTypeLabel = (typeName: string): string => {
        if (typeName === "GiggedClient") return giggedClientTerminology;
        if (typeName === "Talent") return talentTerminology;
        return gigTerminology;
    };

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "Reason",
                        label: "Reason",
                        isSortable: true,
                    },
                    {
                        key: "TypeName",
                        label: "Type",
                        isSortable: true,
                    },
                    {
                        key: "IsResolved",
                        label: "Status",
                        isSortable: true,
                    },
                    {
                        key: "CreatedAt",
                        label: "Created at",
                        isSortable: true,
                    },
                    {
                        key: "CreatedByUserName",
                        label: "Created by",
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false,
                        center: true,
                    },
                ]}
                data={reports.reports}
                renderRow={report => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{report.id}</TableCell>
                        <TableCell>{report.reason}</TableCell>
                        <TableCell>{getTypeLabel(report.typeName)}</TableCell>

                        {report.isResolved ? (
                            <TableChipCell labels={["Resolved"]} />
                        ): (
                            <TableChipCell labels={["Pending"]} color={theme.palette.warning.light} />
                        )}
                        
                        <TableCell>{formatDateTimeString(report.createdAt)}</TableCell>
                        <TableCell>{report.createdByUserName}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.reports.view(report.id)}>
                                <IconButton aria-label="view" >
                                    <VisibilityIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleSortOrderChange}
                setPageIndex={reports.setPageIndex}
                setPageSize={reports.setPageSize}
                pageSize={reports.pageSize}
                pageIndex={reports.pageIndex}
                totalCount={reports.totalCount}
                orderBy={reports.orderBy}
                sortOrder={reports.sortOrder}
                isLoading={reports.isLoading}
            />
        </TableCard>
    );
};

export default ReportsTable;