import { Typography, Link } from "@mui/material";
import { useOrganizationConfig } from "../../api/organization";

const Footer = () => {
    const { organizationConfig } = useOrganizationConfig();

    if (organizationConfig?.hidePoweredByGiggedAi) {
        return null;
    }

    return (
        <>
            <div className="grow bg-white" />
            <div className="flex justify-center h-32 p-8 bg-white">
                <Link
                    href="https://gigged.ai"
                    underline="none"
                >
                    <Typography component="span" variant="body2" sx={{ fontWeight: 600 }}>Powered by</Typography>
                    <img
                        className="max-h-[30px] w-auto mx-auto"
                        src="/gigged-logo.svg"
                        alt="Gigged.ai logo"
                    />
                </Link>
            </div>
        </>
    );
};

export default Footer;