import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Box, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import ApiError from "../../api/common/apiError";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationConfig } from "../../api/organization";
import FormInputLabel from "../FormInputLabel";
import { AgencyDetailsDto } from "../../api/models/agencies";
import { AgencyEditFormValues, useEditAgencyForm } from "./AgencyEditFormValues";
import { AgencyTalentDisplay } from "./AgencyTalentDisplay";
import { DeleteForeverOutlined } from "@mui/icons-material";

export type AgencyEditFormProps = {
    onSubmit: (values: AgencyEditFormValues) => Promise<GigApiFetcherResponse<void>>
    onDelete: () => Promise<GigApiFetcherResponse<void>>
    initialValues: AgencyDetailsDto
    isDeleting: boolean
    isSubmitting: boolean
}

const AgencyEditForm = ({
    onSubmit,
    onDelete,
    initialValues,
    isSubmitting,
    isDeleting
}: AgencyEditFormProps) => {
    const appPaths = useAppPaths();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const theme = useTheme();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const methods = useEditAgencyForm(initialValues);
    const { formState: { isDirty } } = methods;
    const { talentTerminology, talentTerminologyPlural } = useOrganizationConfig();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.agencies.index);
        }
    });
    const [openDeleteDialog, deleteDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDeleteAgency();
        }
    });

    useEffect(() => {
        methods.reset({
            ...initialValues,
            name: initialValues.name ?? "",
            description: initialValues.description ?? "",
            url: initialValues.url ?? ""
        });
    }, [methods.reset, initialValues]);

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.agencies.index);
        }
    };

    const handleFormSubmit = async (values: AgencyEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleDeleteAgency = async () => {
        setSubmissionError(undefined);

        const response = await onDelete();

        if (!response.success) setSubmissionError(response.error);
        else navigate(appPaths.agencies.index);
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title={
                            <FormTextInput
                                name="name"
                                disabled={isSubmitting}
                                variant="standard"
                                placeholder="Type agency name"
                                sx={{ backgroundColor: theme.palette.background.paper }}
                            />
                        }>
                        <Box sx={{
                            minHeight: "25rem",
                            display: "flex",
                            flexDirection: "column",
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            },
                        }}>
                            <Box className="space-y-2">
                                <FormInputLabel>Description</FormInputLabel>
                                <FormTextInput
                                    multiline
                                    name="description"
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Box className="space-y-2">
                                <FormInputLabel>Website</FormInputLabel>
                                <FormTextInput
                                    name="url"
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Box className="mt-2">
                                <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
                                    {talentTerminologyPlural}
                                </Typography>
                                {initialValues?.talents && initialValues.talents.length > 0 ? (
                                    initialValues.talents.map(talent => (
                                        <AgencyTalentDisplay key={talent.talentId} talentId={talent.talentId} name={`${talent?.firstName} ${talent?.lastName}`} />
                                    ))
                                ) : <p>No {talentTerminologyPlural.toLowerCase()} added yet</p>
                                }
                            </Box>
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "auto", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "auto" }
                        }>
                            <LoadingButton disabled={isDeleting || isSubmitting} startIcon={<DeleteForeverOutlined sx={isDeleting ? { opacity: "26%" } : { opacity: "100%" }} />} loading={isDeleting} color="secondary" onClick={openDeleteDialog}>Delete</LoadingButton>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                disabled={!isDirty}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            <ConfirmDialogBox
                {...deleteDialogProps}
                message={"Are you sure you want to delete this agency? This action cannot be undone."}
                warningMessage={
                    initialValues?.talents && initialValues?.talents.length > 0 ?
                        `There ${initialValues.talents.length === 1 ? "is" : "are"} ${initialValues.talents.length} ${initialValues.talents.length === 1 ? talentTerminology.toLowerCase() : talentTerminologyPlural.toLowerCase()} associated with this agency.`
                        : undefined
                }
                confirmButtonText="Delete"
            />
        </>
    );
};

export default AgencyEditForm;