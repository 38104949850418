import { TableCell } from "@mui/material";

export type TableHeaderCellProps = {
    label: string
    center?: boolean
}

const TableHeaderCell = ({
    label,
    center,
}: TableHeaderCellProps) => {
    return (
        <TableCell
            sx={{ textAlign: center ? "center" : "left" }}
        >
            {label}
        </TableCell>
    );
};

export default TableHeaderCell;