import { TableCell } from "@mui/material";
import { Link } from "react-router-dom";

export type FilteredLinkCellProps = {
    count: number
    href: string
}

const FilteredLinkCell = ({
    count,
    href
}: FilteredLinkCellProps) => {
    return (
        <TableCell>
            {count !== 0 ? (
                <Link to={href} className="text-link underline">
                    {count}
                </Link>
            ) : count }
        </TableCell>
    );
};

export default FilteredLinkCell;