import { AuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useAuthContext } from "./authContext";

const useAccessToken = () => {
    const { inProgress, accounts, instance } = useMsal();

    if (accounts.length > 1) {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    const account = accounts[0];
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const { loginRequest } = useAuthContext();

    useEffect(() => {
        if (!account || inProgress != "none") return;

        const getToken = async () => {
            try {
                const authenticationResult = await instance.acquireTokenSilent({
                    account,
                    ...loginRequest,
                });
                setAccessToken(authenticationResult.accessToken);
            }
            catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    await instance.acquireTokenRedirect(loginRequest);
                } else if (error instanceof AuthError) {
                    console.error("MSAL Error:", error.errorCode);
                } else {
                    console.error("An unexpected error occurred during authentication.");
                }
            }
        };
        getToken();
    }, [account, instance, inProgress]);

    return accessToken;
};


export type UseAcquireAccessTokenReturn = {
    acquireAccessToken: () => Promise<string>
    isAuthenticated: boolean
}

export const useAcquireAccessToken = (): UseAcquireAccessTokenReturn => {
    const { inProgress, accounts, instance } = useMsal();

    if (accounts.length > 1) {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    const account = accounts[0];
    const { loginRequest } = useAuthContext();

    const acquireAccessToken = async () => {
        if (!account || inProgress != "none") throw new Error("Tried to acquire access token before user is authenticated.");

        try {
            const authenticationResult = await instance.acquireTokenSilent({
                account,
                ...loginRequest,
            });

            return authenticationResult.accessToken;
        }
        catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                await instance.acquireTokenRedirect(loginRequest);

                // This might look weird but the above causes a redirect so return never happens.
                return "";
            } else if (error instanceof AuthError) {
                console.error("Authentication error occurred.");
                throw new Error("Authentication failed. Please try again.");
            } else {
                console.error("An unexpected error occurred.");
                throw new Error("An unexpected error occurred. Please try again.");
            }
        }
    };

    return {
        acquireAccessToken,
        isAuthenticated: account && !inProgress,
    };
};

export default useAccessToken;