import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { SkillCategoryDetailsDto, UpdateSkillCategoryDto } from "./models/skillCategory";

export const useUpdateSkillCategory = () => useAuthenticatedGigApiFetcher<UpdateSkillCategoryDto, void, { skillCategoryId: string, dto: UpdateSkillCategoryDto }>("PUT",
    ({ skillCategoryId, dto }) => ({
        path: `api/skills/categories/${skillCategoryId}`,
        body: dto
    }));

export const useDeleteSkillCategory = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (skillCategoryId) => ({
        path: `api/skills/categories/${skillCategoryId}`,
    }));

export type UseSkillCategoryReturn<TSkillCategoryId extends string | undefined> = TSkillCategoryId extends undefined ?
    { skillCategory: undefined } :
    {
        skillCategory?: SkillCategoryDetailsDto
        isLoading: boolean
        error?: ApiError
        updateSkillCategory: (dto: UpdateSkillCategoryDto) => Promise<GigApiFetcherResponse<void>>
        deleteSkillCategory: () => Promise<GigApiFetcherResponse<void>>
        isUpdatingSkillCategory: boolean
        isDeletingSkillCategory: boolean
    }

export const useSkillCategory = <TSkillCategoryId extends string | undefined>(skillCategoryId: TSkillCategoryId): UseSkillCategoryReturn<TSkillCategoryId> => {
    const url = `api/skills/categories/${skillCategoryId}`;

    const apiCall = useGiggedApiSWR<SkillCategoryDetailsDto>(url);

    const [updateSkillCategory, isUpdatingSkillCategory] = useUpdateSkillCategory();
    const [deleteSkillCategory, isDeletingSkillCategory] = useDeleteSkillCategory();

    if (skillCategoryId === undefined) return { skillCategory: undefined } as UseSkillCategoryReturn<TSkillCategoryId>;

    return {
        skillCategory: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateSkillCategory: async (dto: UpdateSkillCategoryDto) => {
            const response = await updateSkillCategory({ skillCategoryId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        deleteSkillCategory: async () => {
            const response = await deleteSkillCategory(skillCategoryId);

            return response;
        },
        isUpdatingSkillCategory,
        isDeletingSkillCategory
    } as UseSkillCategoryReturn<TSkillCategoryId>;
};