import { useState } from "react";
import useAccessToken from "../../auth/useAccessToken";
import ApiError, { buildApiError, isApiError } from "./apiError";
import { GigApiResponse } from "./apiResponse";
import buildApiUrl from "./buildApiUrl";

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

const buildHeaders = (token?: string | null, contentType?: string) => {
    const headers = new Headers();

    if (token) headers.set("Authorization", `Bearer ${token}`);
    if (contentType) headers.set("Content-Type", contentType);

    return headers;
};

export type GigApiFetcherResponse<TRes> = {
    success: true
    content: TRes
} | {
    success: false
    error: ApiError
}

export type GigApiFetcherParams<TReq> = {
    path: string,
    method?: HttpMethod
    body?: TReq
    token?: string | null
    allow400?: boolean
}

const gigApiFetcher = async <TReq, TRes>({
    path,
    method = "GET",
    body,
    token,
}: GigApiFetcherParams<TReq>) => {
    const url = buildApiUrl(path);
    
    const response = await fetch(url, {
        method,
        headers: buildHeaders(
            token,
            body ? "application/json" : undefined,
        ),
        body: body ? JSON.stringify(body) : undefined,
    });
    
    if (!response.ok) {            
        const error = await buildApiError(method, url, response);

        throw error;
    }

    const responseContent: GigApiResponse<TRes> = await response.json();

    return responseContent.content;
};

const normalizedGigApiFetcher = async <TReq, TRes>({
    path,
    method = "GET",
    body,
    token,
}: GigApiFetcherParams<TReq>): Promise<GigApiFetcherResponse<TRes>> => {
    try {
        const response = await gigApiFetcher<TReq, TRes>({ path, method, body, token });

        return {
            success: true,
            content: response,
        };
    }
    catch (error) {
        if (isApiError(error)) {
            return {
                success: false,
                error,
            };
        }

        throw error;
    }
};

export const useAuthenticatedGigApiFetcher = <TReq, TRes, TArgs>(
    method: HttpMethod,
    requestBuilder: (arg: TArgs) => { path: string, body?: TReq }):
    [(arg: TArgs) => Promise<GigApiFetcherResponse<TRes>>, boolean] => {
    const token = useAccessToken();
    const [isFetching, setIsFetching] = useState(false);
    
    const fetcher = async (args: TArgs) => {
        
        if (!token) throw new Error("Attempted to make authenticated request without access token.");
        
        const { path, body } = requestBuilder(args);
        
        setIsFetching(true);
        const response = await normalizedGigApiFetcher<TReq, TRes>({ path, method, body, token });
        setIsFetching(false);
        
        return response;
    };

    return [fetcher, isFetching];
};

export default gigApiFetcher;