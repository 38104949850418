import { useNavigate } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useSkillCategories } from "../api/skillCategories";
import { SkillCategoryCreateForm, SkillCategoryCreateFormValues } from "../components/SkillCategoryCreateForm";
import { capitalizeStrings } from "../utils/capitalizeString";

const SkillCategoryCreatePage = () => {
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { createSkillCategory, isCreatingSkillCategory } = useSkillCategories();

    const onSubmit = async (values: SkillCategoryCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createSkillCategory({
            ...values,
            skillCategoryName: capitalizeStrings(values.skillCategoryName)
        });

        if (response.success) {
            successToast("Category successfully created.");
            navigate(appPaths.skills.categories.index);
        }

        return response;
    };

    return (
        <FormContainer>
            <SkillCategoryCreateForm onSubmit={onSubmit} isSubmitting={isCreatingSkillCategory} />
        </FormContainer>
    );
};

export default SkillCategoryCreatePage;
