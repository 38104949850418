import clsx from "clsx";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DropdownIndicatorProps, GroupBase } from "react-select";

const FormSearchDropdownDropdownIndicator = ({
    innerProps,
    selectProps,
    isDisabled
}: DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>) => (
    <span
        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
        {...innerProps}
    >
        {selectProps.menuIsOpen ?
            <ExpandLessIcon
                className={clsx(
                    "h-5 w-5 text-primary-text",
                    isDisabled && "text-[gray]"
                )}
                aria-hidden="true"
            />
            :
            <ExpandMoreIcon
                className={clsx(
                    "h-5 w-5 text-primary-text",
                    isDisabled && "text-[gray]"
                )}
                aria-hidden="true"
            />
        }
    </span>
);

export default FormSearchDropdownDropdownIndicator;