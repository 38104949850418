import { ApiDisputeDto } from "./api/disputes";

export type DisputeDto = Omit<ApiDisputeDto, "disputeId"> & {
    id: string
}

export type ResolveDisputeDto = {
    isTalentWinner: boolean
    messageForTalent: string
    messageForGiggedClient: string
}

export const disputeStatusOptions = (talentTerminology: string, giggedClientTerminology: string) => {
    return [
        { label: "Pending", value: 0 },
        { label: `${giggedClientTerminology} won`, value: 1 },
        { label: `${talentTerminology} won`, value: 2 },
    ];
};


export const buildClientDisputeReasonOptions = (talentTerminology: string) => {
    return [
        `${talentTerminology} did not respond`,
        `${talentTerminology} did not work as I was expecting`
    ];
};

export const buildTalentDisputeReasonOptions = (giggedClientTerminology: string) => {
    return [
        `${giggedClientTerminology} is not releasing the payments`,
        `${giggedClientTerminology} has cancelled the gig`,
    ];
};