export type ChipContainerProps = {
    children: React.ReactNode,
    className?: string
}

export const ChipContainer = ({
    children,
    className,
}: ChipContainerProps) => {
    return (
        <div className={className}>
            <div className="-mt-2 -mr-2 child:mr-2 child:mt-2">
                {children}
            </div>
        </div>
    );
};

export default ChipContainer;
