import { useState } from "react";
import { mapFromApiDisputeDto } from "../mappers/dispute";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { DisputeDto } from "./models/disputes";
import { ApiDisputeDto } from "./models/api/disputes";

export type UseDisputesReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiDisputeDto>>, "data"> & {
        disputes: DisputeDto[]
        sortOrder:  SortOrder
        orderBy: string
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
    };

export const useDisputes = (initialPageSize = 10): UseDisputesReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("CreatedAt");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const url = "api/disputes?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&`;

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiDisputeDto>>(url);

    const disputes = apiCall?.data ? apiCall.data.items.map(mapFromApiDisputeDto) : [];

    return {
        ...apiCall,
        ...apiCall.data,
        disputes,
        sortOrder,
        orderBy,
        setSearchTerm,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
    };
};