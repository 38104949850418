import { GigApiFetcherResponse } from "../api/common/fetching";
import { AddLogoToOrganizationDto } from "../api/models/organization";

import { useOrganizationConfig } from "../api/organization";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import SettingsUpdateForm from "../components/SettingsForm";
import { SettingsUpdateFormValues } from "../components/SettingsForm";
import { useSettingsUpdateForm } from "../components/SettingsForm/SettingsUpdateFormValues";
import { successToast } from "../toast";

const OrganizationSettingsPage = () => {
    const { organizationConfig, isLoading, updateOrganizationConfig, isUpdatingOrganizationConfig, addLogoToOrganization, isAddingLogoToOrganization } = useOrganizationConfig();
    const methods = useSettingsUpdateForm(organizationConfig);

    const handleSubmit = async (values: SettingsUpdateFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await updateOrganizationConfig(values);
        if (response.success) {
            methods.reset(values);
            successToast("Organisation settings updated successfully!");
        } 
        
        return response;
    };

    const handleLogoSubmit = async (dto: AddLogoToOrganizationDto): Promise<GigApiFetcherResponse<void>> => {
        const response = await addLogoToOrganization(dto);

        if(response.success) {
            methods.reset({logoUrl: dto.logoDataUri});
            successToast("Organisation logo updated successfully!");
        }

        return response;
    };

    if (isLoading) return <Loader />;

    return (
        <FormContainer>
            {organizationConfig &&
                <SettingsUpdateForm
                    onSubmit={handleSubmit}
                    onLogoSubmit={handleLogoSubmit}
                    isSubmitting={isUpdatingOrganizationConfig}
                    isSubmittingLogo={isAddingLogoToOrganization}
                    initialValues={organizationConfig}
                    methods={methods}
                />
            }
        </FormContainer>
    );
};

export default OrganizationSettingsPage;