import useSWR, { KeyedMutator } from "swr";

import gigApiFetcher from "./fetching";
import ApiError, { isApiError } from "./apiError";
import { useAcquireAccessToken } from "../../auth/useAccessToken";
import { useNavigate } from "react-router-dom";
import { fourOFour } from "../../Routes";

export type UseGiggedApiSWRReturn<T> = {
    data?: T,
    isLoading: boolean
    isValidating: boolean
    error?: ApiError
    refetch: () => void
    mutate: KeyedMutator<T>
}

export const useGiggedApiSWR = <T,>(url: string | null): UseGiggedApiSWRReturn<T> => {
    const { acquireAccessToken } = useAcquireAccessToken();

    const navigate = useNavigate();

    const fetcher = async (path: string) => {
        try {
            const token = await acquireAccessToken();
            return await gigApiFetcher<unknown, T>({ path, token });
        }
        catch (error) {
            if (isApiError(error) && error.statusCode === 404) {
                navigate(fourOFour);
            }

            console.error(`Unhandled error making request to ${url}:\n`, error);
            throw error;
        }
    };

    const { data, error, mutate, isValidating } = useSWR<T, ApiError>(
        url !== null ? url : null,
        fetcher);

    return {
        data,
        isLoading: !error && !data,
        isValidating,
        error,
        refetch: () => mutate(),
        mutate
    };
};