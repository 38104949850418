import { Typography, Box } from "@mui/material";
import UsersTable from "../components/UsersTable";
import useIsLargeScreen from "../hooks/useIsLargeScreen";

const UsersListPage = () => {
    const isLargeScreen = useIsLargeScreen();
    
    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 ) }}>
                <Typography variant="h4" gutterBottom>Users</Typography>
                <UsersTable />
            </Box>
        </>
    );
};

export default UsersListPage;