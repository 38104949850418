import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "../common/giggedApiSWR";
import { ApiGigStatisticsDto } from "../models/dashboardStatistics/gigStatistics";

export type UseGigStatisticsReturn =
    UseGiggedApiSWRReturn<ApiGigStatisticsDto> &
    {
        statistics?: ApiGigStatisticsDto
        isLoading: boolean
    }

export const useGigStatistics = (): UseGigStatisticsReturn => {

    const url = "api/admin-dashboard/statistics/gigs";

    const apiCall = useGiggedApiSWR<ApiGigStatisticsDto>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        statistics: apiCall.data,
        isLoading: apiCall.isLoading
    };
};