import { Box, useTheme } from "@mui/material";

export type TableCardProps = {
    children: React.ReactNode
}

const TableCard = ({
    children
}: TableCardProps ) => {
    const theme = useTheme();

    return (
        <Box sx={{ border: `1.5px solid ${theme.palette.secondary.light}`, borderRadius: "14px" }}>
            {children}
        </Box>
    );
};

export default TableCard;