import FormContainer from "../components/FormContainer";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useAgencies } from "../api/agencies";
import { AgencyCreateFormValues } from "../components/AgencyCreateForm/AgencyCreateFormValues";
import { AgencyCreateForm } from "../components/AgencyCreateForm";

const AgencyCreatePage = () => {
    const appPaths = useAppPaths();
    const { createAgency, isCreatingAgency } = useAgencies();
    const navigate = useNavigate();

    const onSubmit = async (values: AgencyCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createAgency(values);

        if (response.success) {
            successToast("Agency successfully created.");
            navigate(appPaths.agencies.index);
        }

        return response;
    };

    return (
        <FormContainer>
            <AgencyCreateForm onSubmit={onSubmit} isSubmitting={isCreatingAgency} />
        </FormContainer>
    );
};

export default AgencyCreatePage;
