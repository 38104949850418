import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddIcon from "@mui/icons-material/Add";

import { ClientDetails, ResendInviteClientUserDto } from "../../api/models/client";
import { useClient } from "../../api/client";
import ApiError from "../../api/common/apiError";
import MemberEmailDisplay from "./MemberEmailDisplay";
import InvitedEmailDisplay from "./InvitedEmailDisplay";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import ClientInviteUsersFormDialog from "./ClientInviteUsersFormDialog";
import { ClientInviteUserFormValues } from "./ClientInviteUsersFormValues";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { errorToast, successToast } from "../../toast";
import { ClientEditFormValues, useEditClientForm } from "./ClientEditFormValues";
import { useOrganizationConfig } from "../../api/organization";
import Checkbox from "../Checkbox";
import FormInputLabel from "../FormInputLabel";
import ClientBadgesFormDropdown from "./ClientBadgesFormDropdown";
import FormDropdown, { FormDropdownOption } from "../FormDropdown";
import useAccessToken from "../../auth/useAccessToken";
import { fetchLocationOptions } from "../../api/location";

export type ClientEditFormProps = {
    onSubmit: (values: ClientEditFormValues) => Promise<GigApiFetcherResponse<void>[]>
    initialValues: ClientDetails
    isSubmitting: boolean
}

const ClientEditForm = ({
    initialValues,
    onSubmit,
    isSubmitting,
}: ClientEditFormProps) => {
    const [clientInviteUsersDialogIsOpen, setClientInviteUsersDialogIsOpen] = useState(false);
    const [isClientPersonalInformationLoaded, setIsClientPersonalInformationLoaded] = useState(false);
    const appPaths = useAppPaths();
    const accessToken = useAccessToken();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const theme = useTheme();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const { organizationConfig, giggedClientTerminology, gigTerminologyPlural } = useOrganizationConfig();
    const {
        deleteUserFromClient,
        inviteClientUser,
        deleteClient,
        resendInviteClientUser,
        isResendingInviteClientUser,
        isInvitingClientUser,
        isDeletingUserFromClient,
        isDeletingClient,
        client
    } = useClient(initialValues.id);

    const methods = useEditClientForm({
        ...initialValues,
        badgeId: initialValues.badge?.badgeId || "",
        locationId: initialValues.location?.countryId || "",
        localityId: initialValues.location?.locality.localityId || ""
    }, organizationConfig?.isBadgesEnabled);

    useEffect(() => {
        if (isClientPersonalInformationLoaded) return;

        methods.reset({
            ...initialValues,
            locationId: initialValues?.location?.countryId || "",
            aboutMe: initialValues?.aboutMe || "",
            localityId: initialValues?.location?.locality.localityId ? initialValues.location.locality.localityId : null
        });

        if (initialValues) {
            setIsClientPersonalInformationLoaded(true);
        }
    }, [methods.reset, initialValues, isClientPersonalInformationLoaded]);

    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(true);
    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<FormDropdownOption<string>[]>([]);

    useEffect(function setLocationOptions() {
        if (accessToken) {
            const loadAllLocations = async () => {
                setIsLoadingLocationOptions(true);
                try {
                    const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                    setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
                }
                finally {
                    setIsLoadingLocationOptions(false);
                }
            };
            loadAllLocations();
        }

    }, [accessToken]);

    const [selectedLocationId, setSelectedLocationId] = useState<string | null>();

    useEffect(function setLocalitiesDropdown() {
        setSelectedLocationId(methods.watch("locationId"));
        const selectedLocation = allLocationOptions.find(location => location.value === selectedLocationId);

        if (selectedLocation && selectedLocation.localities) {
            setSelectedLocationLocalities(selectedLocation.localities);
        } else {
            setSelectedLocationLocalities([]);
        }

    }, [allLocationOptions, methods.watch("locationId"), selectedLocationId]);

    useEffect(function resetLocalityOnLocationChange() {
        if (!isClientPersonalInformationLoaded || initialValues?.location?.countryId === selectedLocationId) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId]);

    const { formState: { isDirty } } = methods;

    useEffect(() => {
        methods.reset({
            ...initialValues,
            badgeId: initialValues.badge?.badgeId,
            locationId: initialValues.location?.countryId || "",
            localityId: initialValues.location?.locality.localityId
        });
    }, [methods.reset, initialValues]);

    const handleFormSubmit = async (values: ClientEditFormValues) => {
        setSubmissionError(undefined);

        const responses = await onSubmit(values);

        if (responses.some(response => !response.success)) {
            responses.find(response => {
                !response.success ? setSubmissionError(response.error) : setSubmissionError(undefined);
            });
        }
    };

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.clients.index);
        }
    });

    const [openDeleteDialog, deleteDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDelete();
        }
    });

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.clients.index);
        }
    };

    const handleDelete = async () => {
        setSubmissionError(undefined);

        const response = await deleteClient(initialValues.id);

        if (!response.success) setSubmissionError(response.error);
        else navigate(appPaths.clients.index);
    };

    const handleInviteClientUser = async (values: ClientInviteUserFormValues) => {
        const response = await inviteClientUser(values);

        if (response.success) {
            successToast("Email invitations sent.");
            setClientInviteUsersDialogIsOpen(false);
        }

        return response;
    };

    const handleResendInviteClientUser = async (dto: ResendInviteClientUserDto) => {
        const response = await resendInviteClientUser(dto);

        if (response.success) {
            successToast("Invite resent successfully.");
        } else {
            errorToast("Failed to resend invite");
        }
    
        return response;
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title={
                            <FormTextInput
                                name="name"
                                disabled={isSubmitting}
                                variant="standard"
                                sx={{ backgroundColor: theme.palette.background.paper }}
                            />
                        }>
                        <Box sx={{
                            minHeight: "25rem",
                            display: "flex",
                            flexDirection: "column",
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            }
                        }}>

                            <>
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
                                        Users
                                    </Typography>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={() => setClientInviteUsersDialogIsOpen(true)}
                                        startIcon={<AddIcon />}
                                    >
                                        Invite new user
                                    </Button>

                                </Box>
                                {initialValues.memberUsers.map(memberUser => (
                                    <MemberEmailDisplay key={memberUser.userId} numberOfUsers={initialValues.memberUsers.length} user={memberUser} clientName={initialValues.name} deleteUser={deleteUserFromClient} isDeleting={isDeletingUserFromClient} />
                                ))}
                                {initialValues?.invitedUsers.length > 0 && (
                                    <>
                                        <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
                                            Invites
                                        </Typography>
                                        <InvitedEmailDisplay invitedUsers={initialValues?.invitedUsers} handleResendInviteClientUser={handleResendInviteClientUser}/>
                                    </>
                                )}                            </>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Email: <Typography component="span">{initialValues?.memberUsers[0] ? initialValues?.memberUsers[0].email : "Email not specified"}</Typography>
                            </Typography>
                            <Box className="space-y-2">
                                <FormInputLabel required>Overview</FormInputLabel>
                                <FormTextInput required multiline name="aboutMe" />
                            </Box>
                            <div className="md:flex space-y-4 md:space-x-6 md:space-y-0">
                                {allLocationOptions.length > 0 && (
                                    <div className="w-full md:w-1/2">
                                        <Controller
                                            name="locationId"
                                            control={methods.control}
                                            render={({ field: { onChange, value } }) => (
                                                <FormDropdown
                                                    required
                                                    label="Country"
                                                    value={value || ""}
                                                    options={allLocationOptions}
                                                    disabled={isSubmitting}
                                                    onChange={onChange}
                                                    error={methods.formState.errors.locationId}
                                                    isLoadingOptions={isLoadingLocationOptions}
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                                <div className="w-full md:w-1/2">
                                    {selectedLocationLocalities && selectedLocationLocalities.length > 0 && (
                                        <Controller
                                            name="localityId"
                                            control={methods.control}
                                            render={({ field: { onChange, value } }) => (
                                                <FormDropdown
                                                    required
                                                    label="City"
                                                    value={value || ""}
                                                    options={selectedLocationLocalities}
                                                    disabled={selectedLocationLocalities.length < 1 || isSubmitting}
                                                    onChange={onChange}
                                                    error={methods.formState.errors.localityId}
                                                    isLoadingOptions={isLoadingLocationOptions}
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                            {organizationConfig?.isBadgesEnabled && (
                                <ClientBadgesFormDropdown
                                    organizationConfig={organizationConfig}
                                    methods={methods}
                                    isSubmitting={isSubmitting}
                                />
                            )}
                            <FormErrors messages={submissionError?.userMessages} />
                            <Box sx={
                                isLargeScreen ?
                                    { display: "flex", justifyContent: "space-between", marginTop: 2, gap: 2 } :
                                    { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: 2 }
                            }>
                                <LoadingButton
                                    color="secondary"
                                    startIcon={<DeleteForeverOutlinedIcon sx={isSubmitting || isDeletingClient ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting || isDeletingClient}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                    onClick={openDeleteDialog}
                                >
                                    Delete
                                </LoadingButton>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting || isDeletingClient ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting || isDeletingClient}
                                    disabled={!isDirty || isDeletingClient}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            {clientInviteUsersDialogIsOpen && (
                <ClientInviteUsersFormDialog
                    isOpen={true}
                    onClose={() => setClientInviteUsersDialogIsOpen(false)}
                    handleInviteClientUser={handleInviteClientUser}
                    isInviting={isInvitingClientUser}
                />
            )}
            <ConfirmDialogBox
                {...deleteDialogState}
                message={`Are you sure you want to delete this ${giggedClientTerminology.toLowerCase()}? This action cannot be undone.`}
                warningMessage={client?.hasAnyOngoingGigs ? `This ${giggedClientTerminology.toLowerCase()} has one or more ongoing ${gigTerminologyPlural.toLowerCase()}.` : undefined}
                confirmButtonText="Delete"
            />
        </>
    );
};

export default ClientEditForm;