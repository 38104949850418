import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { FormProvider } from "react-hook-form";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import FormInputLabel from "../FormInputLabel";
import FormRadioInput from "../FormRadioInput";
import FormTextInput from "../FormTextInput";
import { DisputeResolveFormValues, useResolveDisputeForm } from "./DisputeResolveFormValues";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import ConfirmDialogBox from "../ConfirmDialogBox";
import { useState } from "react";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import FormErrors from "../FormErrors";
import { useOrganizationConfig } from "../../api/organization";

export type DisputeResolveFormProps = {
    handleCloseDialog: () => void
    onSubmit: (dto: DisputeResolveFormValues) => Promise<GigApiFetcherResponse<void>>
    loading: boolean
}

const DisputeResolveForm = ({
    handleCloseDialog,
    onSubmit,
    loading
}: DisputeResolveFormProps) => {
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const methods = useResolveDisputeForm();
    const { handleSubmit, formState: { dirtyFields, errors } } = methods;
    const isLargeScreen = useIsLargeScreen();
    const isDirtyAlt = Object.keys(dirtyFields).length > 0;
    const { giggedClientTerminology, talentTerminology } = useOrganizationConfig();

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleCloseDialog();
        }
    });

    const handleSubmitForm = async (values: DisputeResolveFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleCloseResolveFormDialog = () => {
        if (isDirtyAlt) {
            openCancelDialog();
        } else {
            handleCloseDialog();
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <Box>
                    <form
                        noValidate
                        onSubmit={handleSubmit(handleSubmitForm)}>
                        <Box className="space-y-4">
                            <FormRadioInput
                                name="isTalentWinner"
                                label="Select winning party"
                                disabled={loading}
                                error={errors.isTalentWinner}
                                options={[
                                    {
                                        value: "true",
                                        label: talentTerminology
                                    },
                                    {
                                        value: "false",
                                        label: giggedClientTerminology
                                    },
                                ]}
                            />
                            <Box className="space-y-2">
                                <FormInputLabel required>{`Message to ${talentTerminology.toLowerCase()}`}</FormInputLabel>
                                <FormTextInput
                                    required
                                    disabled={loading}
                                    multiline
                                    name="messageForTalent"
                                />
                            </Box>
                            <Box className="space-y-2">
                                <FormInputLabel required>{`Message to ${giggedClientTerminology.toLowerCase()}`}</FormInputLabel>
                                <FormTextInput
                                    required
                                    disabled={loading}
                                    multiline
                                    name="messageForGiggedClient"
                                />
                            </Box>
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box className="flex flex-col-reverse md:flex-row justify-end items-center md:space-x-4 mt-8">
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={handleCloseResolveFormDialog}
                                sx={!isLargeScreen ? { width: "100%" } : { width: "auto" }}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirtyAlt || loading ? { opacity: "26%" } : { opacity: "100%" }} />}
                                type="submit"
                                color="primary"
                                loading={loading}
                                disabled={!isDirtyAlt}
                                sx={!isLargeScreen ? { marginBottom: 2, width: "100%" } : { marginBottom: 0 }}
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </form>
                </Box>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default DisputeResolveForm;