import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Typography, Divider } from "@mui/material";
import { useOrganizationConfig } from "../../api/organization";

export type AgencyTalentDisplayProps = {
    name: string
    talentId: string
}

export const AgencyTalentDisplay = ({
    name,
    talentId
}: AgencyTalentDisplayProps) => {
    const { gigDashboardDomain } = useOrganizationConfig();

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", paddingY: "10px" }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <AccountCircleIcon fontSize="large" sx={{ marginRight: 1 }} />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography component="span" className="hover:underline hover:text-link">
                            <a href={`https://${gigDashboardDomain}/find-talent/${talentId}`} target="_blank">
                                {name}
                            </a>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider />
        </Box>
    );
};