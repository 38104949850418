import { useState } from "react";
import { convertFileToDataUrl } from "../../utils/convertFileToDataUrl";
import { formatFileSize } from "../../utils/formatFileSizeInKb";
import config from "../../configuration";
import urlHasAllowedExtension from "../../utils/urlHasAllowedExtension";

export type UseFormFileUploadInputStateParams = {
    maxFileSizeKb: number
    initialUrl?: string
    allowPdfs?: boolean
}

const useFormFileUploadInputState = ({
    maxFileSizeKb,
    initialUrl,
    allowPdfs,
}: UseFormFileUploadInputStateParams) => {
    const [dataUrl, setDataUrl] = useState<string | undefined>();
    const [fileSizeBytes, setFileSizeBytes] = useState<number | undefined>();
    const [fileSizeDescription, setFileSizeDescription] = useState("");
    const [fileName, setFileName] = useState("");
    const [uploadedIsImage, setUploadedIsImage] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isDirty, setIsDirty] = useState(false);

    const allowedFileExtensions = allowPdfs ?
        [...config.allowedImageFileExtensions, "pdf"] :
        config.allowedImageFileExtensions;

    const initialIsImage: boolean = !!initialUrl && urlHasAllowedExtension(initialUrl, allowedFileExtensions);

    const loadFile = async (file: File) => {
        setIsDirty(true);
        setError(undefined);
        setUploadedIsImage(false);
        setDataUrl(undefined);
        setFileSizeBytes(file.size);

        try {
            const fileNameParts = file.name.split(".");
            
            if (fileNameParts.length === 1) {
                setError(`Please upload one of these file types: ${allowedFileExtensions.join(", ")}.`);
                return;
            }

            const fileExtension = fileNameParts[fileNameParts.length - 1].toLocaleLowerCase();

            if (!allowedFileExtensions.includes(fileExtension)) {
                setError(`'${fileExtension}' is not a valid file type, please upload one of these: ${allowedFileExtensions.join(", ")}.`);
                return;
            }

            setUploadedIsImage(fileExtension !== "pdf");
        }
        catch (error) {
            setError(`Invalid file type, please upload one of these: ${allowedFileExtensions.join(", ")}.`);
            return;
        }

        if (file.size / 1024 > maxFileSizeKb) {
            setError(`The maximum allowed file size is ${maxFileSizeKb}KB and your file is ${formatFileSize(file.size)}.`);
            return;
        }
        
        const dataUrl = await convertFileToDataUrl(file);
        
        setDataUrl(dataUrl);
        setFileName(file.name);
        setFileSizeDescription(formatFileSize(file.size));
    };

    const handleReset = () => {
        setIsDirty(false);
        setError(undefined);
        setUploadedIsImage(false);
    };

    return {
        loadFile,
        urlOrDataUri: isDirty ? dataUrl : initialUrl,
        isImage: isDirty ? uploadedIsImage : initialIsImage,
        fileSizeBytes,
        fileSizeDescription,
        fileName,
        error,
        isDirty,
        reset: handleReset,
    };
};

export default useFormFileUploadInputState;
