import { useMediaQuery, Box, Typography, useTheme } from "@mui/material";
import { useOrganizationConfig } from "../api/organization";
import GigsTable from "../components/GigsTable";
import pluralizeString from "../utils/pluralizeString";

const GigsListPage = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const { gigTerminology } = useOrganizationConfig();
    
    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 ) }}>
                <Typography variant="h4" gutterBottom>{pluralizeString(gigTerminology)}</Typography>
                <GigsTable />
            </Box>
        </>
    );
};

export default GigsListPage;
