import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "../common/giggedApiSWR";
import { ApiReportStatisticsDto } from "../models/dashboardStatistics/reportStatistics";

export type UseReportStatisticsReturn =
    UseGiggedApiSWRReturn<ApiReportStatisticsDto> &
    {
        statistics?: ApiReportStatisticsDto
        isLoading: boolean
    }

export const useReportStatistics = (): UseReportStatisticsReturn => {

    const url = "api/admin-dashboard/statistics/reports";

    const apiCall = useGiggedApiSWR<ApiReportStatisticsDto>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        statistics: apiCall.data,
        isLoading: apiCall.isLoading
    };
};