import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Button } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

export type SignOutButtonProps = {
    className?: string
}

export const SignOutButton = ({
    className
}: SignOutButtonProps) => {
    const { instance, inProgress } = useMsal();
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const isLoading = isLoggingIn || inProgress === InteractionStatus.HandleRedirect;

    const handleLogout = () => {
        setIsLoggingIn(true);
        try {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoggingIn(false);
        }
    };

    return (
        <Button
            onClick={handleLogout}
            variant="text"
            color="secondary"
            size="medium"
            className={className}
        >
            <ExitToAppIcon className="mr-4" />
            {isLoading ? "Loading..." : "Sign out"}
        </Button>
    );
};