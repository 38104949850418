import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiResolveReportDto } from "./models/api/reports";
import { ApiReportDetailsDto } from "./models/api/reports";

export const useResolveReport = () => useAuthenticatedGigApiFetcher<ApiResolveReportDto, void, { reportId: string, dto: ApiResolveReportDto }>("POST",
    ({ reportId, dto }) => ({
        path: `api/reports/${reportId}/actions/resolve`,
        body: dto
    }));

export type UseReportReturn<TReportId extends string | undefined> = TReportId extends undefined ?
    { report: undefined } :
    {
        report: ApiReportDetailsDto
        isLoading: boolean
        resolveReport: (reportId: string, dto: ApiResolveReportDto) => Promise<GigApiFetcherResponse<void>>
        isResolvingReport: boolean
    }

export const useReport = <TReportId extends string | undefined>(reportId: TReportId) => {
    const [resolveReport, isResolvingReport] = useResolveReport();
    
    const url = `api/reports/${reportId}`;

    const apiCall = useGiggedApiSWR<ApiReportDetailsDto>(url);

    if (reportId === undefined) return { report: undefined } as UseReportReturn<TReportId>;

    return {
        ...apiCall,
        report: apiCall.data,
        isLoading: apiCall.isLoading,
        resolveReport: async (reportId: string, dto: ApiResolveReportDto) => {
            const response = await resolveReport({ reportId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        isResolvingReport
    } as UseReportReturn<TReportId>;
};