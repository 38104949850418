const urlHasAllowedExtension = (url: string, allowedExtensions: string[]): boolean => {
    if (!url) return false;
    
    const urlWithQueryStripped = url.split("?")[0];

    const urlComponents = urlWithQueryStripped?.split(".");

    if (urlComponents.length < 2) return false;

    const extension = urlComponents[urlComponents.length -1];

    return allowedExtensions.includes(extension);
};

export default urlHasAllowedExtension;
