import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { TalentDetailsDto } from "../../api/models/talent";
import { useTalent } from "../../api/talent";
import ApiError from "../../api/common/apiError";
import ConfirmDialogBox from "../ConfirmDialogBox";
import FormTextInput from "../FormTextInput";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import { useAppPaths } from "../../Routes";
import FormErrors from "../FormErrors";
import { useNavigate } from "react-router-dom";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { TalentEditFormValues, useEditTalentForm } from "./TalentEditFormValues";
import { useOrganizationConfig } from "../../api/organization";
import FormInputLabel from "../FormInputLabel";
import { talentTooltips } from "../../tooltipsContent";
import FormRadioInput from "../FormRadioInput";
import FormTextAreaInput from "../FormTextAreaInput";
import FormDateInput from "../FormDateInput";
import {
    ReusableDropdown as RatePackDropdown,
    ReusableDropdown as CompetencyLevelDropdown,
    ReusableDropdown as WheelPositionDropdown,
    ReusableDropdown as DocumentationLanguageDropdown
} from "../ReusableDropdown";
import { fetchRatePackOptions } from "../../api/ratePacks";
import { fetchCompetencyLevelOptions } from "../../api/competencyLevel";
import { fetchDocumentationLanguageOptions } from "../../api/languages";
import { fetchWheelPositionOptions } from "../../api/wheelPositions";
import { TalentAgencyFormDropdown } from "./TalentAgencyFormDropdown";
import useAccessToken from "../../auth/useAccessToken";
import FormDropdown, { FormDropdownOption } from "../FormDropdown";
import { fetchLocationOptions } from "../../api/location";

export type TalentEditFormProps = {
    onSubmit: (values: TalentEditFormValues) => Promise<GigApiFetcherResponse<void>>
    initialValues: TalentDetailsDto
    isSubmitting: boolean
}

const TalentEditForm = ({
    initialValues,
    onSubmit,
    isSubmitting,
}: TalentEditFormProps) => {
    const accessToken = useAccessToken();
    const appPaths = useAppPaths();
    const theme = useTheme();
    const navigate = useNavigate();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const { talent } = useTalent(initialValues.id);
    const { talentTerminology, organizationConfig } = useOrganizationConfig();
    const [isTalentPersonalInformationLoaded, setIsTalentPersonalInformationLoaded] = useState<boolean>(false);

    const methods = useEditTalentForm({
        ...initialValues,
        agencyId: organizationConfig?.isTalentAgencyEnabled ? initialValues?.agency?.id ?? "None" : null,
        organizationAvailability: initialValues.organizationAvailability ?? "",
        isQualiopiRequired: initialValues.isQualiopiRequired ? "true" : "false",
        vendorName: initialValues.vendorName ?? "",
        presentationStyle: initialValues.presentationStyle ?? "",
        insuranceExpiryDate: initialValues.insuranceExpiryDate ?? null,
        contractSentDate: initialValues.contractSentDate ?? null,
        ratePackId: initialValues.ratePack?.id ?? "",
        competencyLevelId: initialValues.competencyLevel?.id ?? "",
        wheelPositionId: initialValues.wheelPosition?.id ?? null,
        documentationLanguageId: initialValues.documentationLanguage?.id ?? null,
        knownCompetitors: initialValues.knownCompetitors ?? "",
        rlc: initialValues.rlc ?? "",
        notes: initialValues.notes ?? "",
        professionalBackground: initialValues.professionalBackground ?? "",
        locationId: initialValues.location?.countryId || "",
        localityId: initialValues.location?.locality?.localityId || ""
    });

    const { formState: { isDirty } } = methods;
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.talents.index);
        }
    });

    useEffect(() => {
        if (isTalentPersonalInformationLoaded) return;

        methods.reset({
            ...initialValues,
            agencyId: organizationConfig?.isTalentAgencyEnabled ? initialValues?.agency?.id ?? "None" : null,
            organizationAvailability: initialValues.organizationAvailability ?? "",
            isQualiopiRequired: initialValues.isQualiopiRequired ? "true" : "false",
            vendorName: initialValues.vendorName ?? "",
            presentationStyle: initialValues.presentationStyle ?? "",
            ratePackId: initialValues.ratePack?.id ?? "",
            competencyLevelId: initialValues.competencyLevel?.id ?? "",
            wheelPositionId: initialValues.wheelPosition?.id ?? null,
            documentationLanguageId: initialValues.documentationLanguage?.id ?? null,
            knownCompetitors: initialValues.knownCompetitors ?? "",
            rlc: initialValues.rlc ?? "",
            notes: initialValues.notes ?? "",
            professionalBackground: initialValues.professionalBackground ?? "",
            locationId: initialValues?.location?.countryId || "",
            localityId: initialValues?.location?.locality?.localityId || ""
        });

        if (initialValues) {
            setIsTalentPersonalInformationLoaded(true);
        }
    }, [initialValues, methods, isTalentPersonalInformationLoaded]);

    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(true);
    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<FormDropdownOption<string>[]>([]);

    useEffect(function setLocationOptions() {
        if (accessToken) {
            const loadAllLocations = async () => {
                setIsLoadingLocationOptions(true);
                try {
                    const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                    setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
                }
                finally {
                    setIsLoadingLocationOptions(false);
                }
            };
            loadAllLocations();
        }

    }, [accessToken]);

    const [selectedLocationId, setSelectedLocationId] = useState<string | null>();

    useEffect(function setLocalitiesDropdown() {
        setSelectedLocationId(methods.watch("locationId"));
        const selectedLocation = allLocationOptions.find(location => location.value === selectedLocationId);

        if (selectedLocation && selectedLocation.localities) {
            setSelectedLocationLocalities(selectedLocation.localities);
        } else {
            setSelectedLocationLocalities([]);
        }

    }, [allLocationOptions, methods.watch("locationId"), selectedLocationId]);

    useEffect(function resetLocalityOnLocationChange() {
        if (!isTalentPersonalInformationLoaded || initialValues?.location?.countryId === selectedLocationId) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId]);

    const handleFormSubmit = async (values: TalentEditFormValues) => {
        setSubmissionError(undefined);
        const response = await onSubmit({
            ...values,
            agencyId: !organizationConfig?.isTalentAgencyEnabled || values.agencyId === "None" ? null : values.agencyId,
            organizationAvailability: values.organizationAvailability === "" ? null : values.organizationAvailability,
            vendorName: values.vendorName === "" ? null : values.vendorName,
            presentationStyle: values.presentationStyle === "" ? null : values.presentationStyle,
            knownCompetitors: values.knownCompetitors === "" ? null : values.knownCompetitors,
            rlc: values.rlc === "" ? null : values.rlc,
            notes: values.notes === "" ? null : values.notes,
            professionalBackground: values.professionalBackground === "" ? null : values.professionalBackground,
            isQualiopiRequired: values.isQualiopiRequired === "true" ? "true" : "false"
        });

        if (response.success) {
            methods.reset({
                ...values,
                agencyId: organizationConfig?.isTalentAgencyEnabled ? values?.agencyId ?? "None" : null,
                isQualiopiRequired: values.isQualiopiRequired === "true" ? "true" : "false",
                locationId: values?.locationId || "",
                localityId: values?.localityId || ""
            });
        } else {
            setSubmissionError(response.error);
        }
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.talents.index);
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleFormSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title={talent?.firstName + " " + talent?.lastName}>
                        <Box sx={{
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            }
                        }}>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                ID: <Typography component="span">{initialValues.id}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Email: <Typography component="span"><a className="hover:underline" href={`mailto:${initialValues.email}`}>{initialValues.email}</a></Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Phone number: <Typography component="span">{initialValues.phoneNumber || "Not specified"}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                City: <Typography component="span">{`${talent?.location?.locality.locality} ${talent?.location?.locality.region ? ` (${talent?.location?.locality.region})` : ""}`}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Country: <Typography component="span">{`${initialValues.location?.country}` || "Not specified"}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Completed hours: <Typography component="span">{initialValues.gigCompletedHours}</Typography>
                            </Typography>
                            <Divider />
                            <Box className="space-y-4">
                                {organizationConfig?.isTalentAvailabilityEnabled && (
                                    <Box className="space-y-2">
                                        <FormInputLabel tooltipText={talentTooltips.talentOrganizationAvailability(talentTerminology)}>{`${talentTerminology} availability`}</FormInputLabel>
                                        <FormTextInput name="organizationAvailability" />
                                    </Box>
                                )}
                                {organizationConfig?.isTalentAgencyEnabled && (
                                    <TalentAgencyFormDropdown
                                        methods={methods}
                                        isSubmitting={isSubmitting}
                                    />
                                )}
                            </Box>
                            <FormRadioInput
                                name="isQualiopiRequired"
                                label="Is Qualiopi Required?"
                                disabled={isSubmitting}
                                options={[
                                    {
                                        value: "true",
                                        label: "Yes",
                                    },
                                    {
                                        value: "false",
                                        label: "No",
                                    },
                                ]}
                            />
                            <Box className="space-y-2">
                                <FormInputLabel>Vendor name</FormInputLabel>
                                <FormTextInput name="vendorName" placeholder="Add vendor name" />
                            </Box>
                            <FormTextAreaInput
                                name="presentationStyle"
                                label="Presentation style"
                                placeholder={`Add ${talentTerminology}s presentation style`}
                                sx={{ marginBottom: theme.spacing(2) }}
                            />
                            <Controller
                                name="contractSentDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        name="contractSentDate"
                                        label="Contract sent date"
                                        value={value || null}
                                        onChange={onChange}
                                        error={methods.formState.errors.contractSentDate}
                                    />
                                )}
                            />
                            <Controller
                                name="insuranceExpiryDate"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateInput
                                        name="insuranceExpiryDate"
                                        label="Insurance expiry date"
                                        value={value || null}
                                        onChange={onChange}
                                        error={methods.formState.errors.insuranceExpiryDate}
                                    />
                                )}
                            />
                            <Controller
                                name="ratePackId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <RatePackDropdown
                                        required
                                        error={methods.formState.errors.ratePackId}
                                        onChange={onChange}
                                        value={value || ""}
                                        label="Select rate pack"
                                        fetchOptions={fetchRatePackOptions}
                                    />
                                )}
                            />
                            <Controller
                                name="competencyLevelId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <CompetencyLevelDropdown
                                        required
                                        error={methods.formState.errors.competencyLevelId}
                                        onChange={onChange}
                                        value={value || ""}
                                        label="Select competency level"
                                        fetchOptions={fetchCompetencyLevelOptions}
                                    />
                                )}
                            />
                            <Controller
                                name="wheelPositionId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <WheelPositionDropdown
                                        error={methods.formState.errors.wheelPositionId}
                                        onChange={onChange}
                                        value={value || ""}
                                        label="Wheel position"
                                        fetchOptions={fetchWheelPositionOptions}
                                    />
                                )}
                            />
                            <Controller
                                name="documentationLanguageId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <DocumentationLanguageDropdown
                                        error={methods.formState.errors.documentationLanguageId}
                                        onChange={onChange}
                                        value={value || ""}
                                        label="Documentation language"
                                        fetchOptions={fetchDocumentationLanguageOptions}
                                    />
                                )}
                            />
                            <div className="md:flex space-y-4 md:space-x-6 md:space-y-0">
                                {allLocationOptions.length > 0 && (
                                    <div className="w-full md:w-1/2">
                                        <Controller
                                            name="locationId"
                                            control={methods.control}
                                            render={({ field: { onChange, value } }) => (
                                                <FormDropdown
                                                    required
                                                    label="Country"
                                                    value={value || ""}
                                                    options={allLocationOptions}
                                                    disabled={isSubmitting}
                                                    onChange={onChange}
                                                    error={methods.formState.errors.locationId}
                                                    isLoadingOptions={isLoadingLocationOptions}
                                                />
                                            )}
                                        />
                                    </div>
                                )}
                                <div className="w-full md:w-1/2">
                                    {selectedLocationLocalities && selectedLocationLocalities.length > 0 && (
                                        <Controller
                                            name="localityId"
                                            control={methods.control}
                                            render={({ field: { onChange, value } }) => (
                                                <FormDropdown
                                                    required
                                                    label="City"
                                                    value={value || ""}
                                                    options={selectedLocationLocalities}
                                                    disabled={selectedLocationLocalities.length < 1 || isSubmitting}
                                                    onChange={onChange}
                                                    error={methods.formState.errors.localityId}
                                                    isLoadingOptions={isLoadingLocationOptions}
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                            <Box className="space-y-4">
                                <Box className="space-y-2">
                                    <FormInputLabel>{"Known competitors"}</FormInputLabel>
                                    <FormTextInput name="knownCompetitors" placeholder="Add any known competitors" />
                                </Box>
                                <Box className="space-y-2">
                                    <FormInputLabel>{"RLC"}</FormInputLabel>
                                    <FormTextInput name="rlc" placeholder={`Add this ${talentTerminology}s main RLC`} />
                                </Box>
                                <FormTextAreaInput
                                    name="notes"
                                    label="Notes"
                                    placeholder="Add any relevant notes"
                                    sx={{ marginBottom: theme.spacing(2) }}
                                    minRows={3}
                                />
                                <FormTextAreaInput
                                    name="professionalBackground"
                                    label="Professional Background"
                                    placeholder="Add something about their background"
                                    sx={{ marginBottom: theme.spacing(2) }}
                                    minRows={3}
                                />
                            </Box>

                            <FormErrors messages={submissionError?.userMessages} />

                            <Box className="flex justify-end !mt-8">
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting}
                                    disabled={!isDirty}
                                    className="w-full md:w-auto"
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider >
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default TalentEditForm;