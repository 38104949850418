import { dashboardOverviewTooltips } from "../../tooltipsContent";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardSection from "../../components/Dashboard/DashboardSection";
import DashboardSubSection from "../../components/Dashboard/DashboardSubSection";
import { ApiDisputeStatisticsDto } from "../../api/models/dashboardStatistics/disputeStatistics";

export type DashboardDisputesSectionProps = {
    statistics?: ApiDisputeStatisticsDto
}

const DashboardDisputesSection = ({
    statistics
}: DashboardDisputesSectionProps) => {

    return (
        <DashboardSection
            title="Disputes"
        >
            <div className="flex flex-col space-y-2 w-full">
                <div className="flex flex-row space-x-2">
                    <DashboardCard
                        title="Total disputes"
                        statistic={statistics?.disputesCount}
                        tooltip={dashboardOverviewTooltips.disputesTotal}
                    />
                </div>
                <DashboardSubSection
                    title="Disputes by type"
                    tooltip={dashboardOverviewTooltips.disputesByType}
                >
                    {statistics?.disputeTypeByCount && statistics?.disputeTypeByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics.disputeTypeByCount.map(disputeType => (
                                <DashboardCard
                                    key={disputeType.disputeTypeName}
                                    title={disputeType.disputeTypeName}
                                    statistic={disputeType.disputeTypeCount}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
            </div>
        </DashboardSection>
    );
};

export default DashboardDisputesSection;