import { Box, TableCell, TableSortLabel } from "@mui/material";
import { SortOrder } from "../../api/common/sortOrder";
import { visuallyHidden } from "@mui/utils";

export type TableHeaderCellWithSortProps<T, TFields extends T> = {
    id: T
    label: string
    orderBy?: TFields
    sortOrder?: SortOrder
    onOrderByClick?: (property: T) => void
    center?: boolean
}

const TableHeaderCellWithSort = <T, TFields extends T>({
    id,
    label,
    orderBy,
    sortOrder,
    onOrderByClick,
    center,
}: TableHeaderCellWithSortProps<T, TFields>) => {
    const createOrderByHandler = (property: T) => () => {
        if (onOrderByClick) onOrderByClick(property);
    };

    return (
        <TableCell
            sortDirection={orderBy === id ? sortOrder : false}
            sx={{ textAlign: center ? "center" : "left" }}
        >
            <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? sortOrder : "asc"}
                onClick={createOrderByHandler(id)}
            >
                {label}
                {orderBy === id ? (
                    <Box component="span" sx={visuallyHidden}>
                        {sortOrder === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                ) : null}
            </TableSortLabel>
        </TableCell>
    );
};

export default TableHeaderCellWithSort;