import { TableCell } from "@mui/material";
import { Link } from "react-router-dom";

export type FilterLinkCellProps = {
    count?: number
    href: string
}

const FilterLinkCell = ({
    count,
    href
}: FilterLinkCellProps) => {
    return (
        <TableCell>
            {typeof count === "number" ? (
                count !== 0 ? (
                    <Link to={href} className="text-link underline">
                        {count}
                    </Link>
                ) : count
            ) : undefined}
        </TableCell>
    );
};

export default FilterLinkCell;