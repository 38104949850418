import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import PageLayout from "./components/PageLayout";
import AppRoutes from "./Routes";
import AuthContextProvider from "./auth/AuthContextProvider";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/lab";
import ScrollToTop from "./utils/scrollToTop";

function MyApp() {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Router>
                    <ScrollToTop />
                    <AuthContextProvider>
                        <PageLayout>
                            <AppRoutes />
                        </PageLayout>
                    </AuthContextProvider>
                </Router>
            </LocalizationProvider>
            <ToastContainer />
        </>
    );
}

export default MyApp;
