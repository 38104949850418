import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useAgency } from "../api/agency";
import { AgencyEditForm, AgencyEditFormValues } from "../components/AgencyEditForm";
import { useAgencyId } from "../Routes";

const AgencyEditPage = () => {
    const agencyId = useAgencyId();
    const agency = useAgency(agencyId as string);

    const onSubmit = async (values: AgencyEditFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await agency.updateAgency({
            ...values,
            name: values.name,
            description: values.description ?? "",
            url: values.url ?? ""
        });

        if (response.success) {
            successToast("Agency successfully updated.");
        }

        return response;
    };

    const handleDelete = async () => {
        const response = await agency.deleteAgency();

        if (response.success) {
            successToast("Agency successfully deleted.");
        }

        return response;
    };

    if (agency.isLoading || !agency.agency) return <Loader />;

    return (
        <FormContainer>
            <AgencyEditForm
                initialValues={agency.agency}
                onSubmit={onSubmit}
                onDelete={handleDelete}
                isSubmitting={agency.isUpdatingAgency}
                isDeleting={agency.isDeletingAgency}
            />
        </FormContainer>
    );
};

export default AgencyEditPage;
