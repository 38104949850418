import { DeleteForeverOutlined } from "@mui/icons-material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, MenuItem, Select, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationConfig } from "../../api/organization";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useAppPaths } from "../../Routes";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import FormCard from "../FormCard";
import FormErrors from "../FormErrors";
import FormTextInput from "../FormTextInput";
import { BadgeEditFormValues, useEditBadgeForm } from "./BadgeEditFormValues";
import { BadgeSummaryDto, badgeTypeOptions } from "../../api/models/badge";
import FormInputLabel from "../FormInputLabel";

export type BadgeEditFormProps = {
    onSubmit: (values: BadgeEditFormValues) => Promise<GigApiFetcherResponse<void>>
    onDelete: () => Promise<GigApiFetcherResponse<void>>
    initialValues: BadgeSummaryDto
    isDeleting: boolean
    isSubmitting: boolean
}

const BadgeEditForm = ({
    onSubmit,
    onDelete,
    initialValues,
    isDeleting,
    isSubmitting
}: BadgeEditFormProps) => {
    const { talentTerminology, talentTerminologyPlural, giggedClientTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const navigate = useNavigate();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.badges.index);
        }
    });
    const [openDeleteDialog, deleteDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleDelete();
        }
    });

    const methods = useEditBadgeForm(initialValues);

    useEffect(() => {
        methods.reset({
            ...initialValues,
            typeId: initialValues.typeId
        });
    }, [methods.reset, initialValues]);

    const {
        formState: { isDirty },
    } = methods;

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.badges.index);
        }
    };

    const handleSubmit = async (values: BadgeEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) {
            setSubmissionError(response.error);
        }
    };


    const handleDelete = async () => {
        setSubmissionError(undefined);

        const response = await onDelete();

        if (!response.success) setSubmissionError(response.error);
        else navigate(appPaths.badges.index);
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        withoutTitleUnderline={true}
                        width="100%"
                        title={
                            <FormTextInput
                                name="name"
                                disabled={isSubmitting}
                                variant="standard"
                                placeholder="Type badge name"
                                sx={{ backgroundColor: theme.palette.background.paper }}
                            />
                        }>
                        <Box sx={{
                            minHeight: "25rem",
                            display: "flex",
                            flexDirection: "column",
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            },
                        }}>
                            <Box className="space-y-2">
                                <FormInputLabel>Description</FormInputLabel>
                                <FormTextInput
                                    multiline
                                    name="description"
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Box className="space-y-2">
                                <FormInputLabel>Image file name</FormInputLabel>
                                <FormTextInput
                                    name="imageFileName"
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Box className="space-y-2 flex flex-col">
                                <FormInputLabel>Badge type</FormInputLabel>
                                <Controller
                                    name="typeId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            variant="outlined"
                                            color="secondary"
                                            disabled={isSubmitting}
                                            displayEmpty
                                        >
                                            <MenuItem disabled >Select badge type</MenuItem>
                                            {
                                                badgeTypeOptions.length > 0 && badgeTypeOptions(talentTerminology, giggedClientTerminology).map(option => (
                                                    <MenuItem key={option?.value} value={option?.value}>{option?.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    )}
                                />
                            </Box>
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "auto", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "auto" }
                        }>
                            <LoadingButton disabled={isDeleting || isSubmitting} startIcon={<DeleteForeverOutlined sx={isDeleting ? { opacity: "26%" } : { opacity: "100%" }} />} loading={isDeleting} color="secondary" onClick={openDeleteDialog}>Delete</LoadingButton>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={!isDirty || isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                disabled={!isDirty}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Save changes
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            <ConfirmDialogBox
                {...deleteDialogProps}
                message={"Are you sure you want to delete this badge? This action cannot be undone."}
                warningMessage={
                    initialValues?.talentCount > 0 ?
                        `There ${initialValues.talentCount > 1 ? "is" : "are"} ${initialValues.talentCount} ${initialValues.talentCount === 1 ? talentTerminology.toLowerCase() : talentTerminologyPlural.toLowerCase()} associated with this badge.`
                        : undefined
                }
                confirmButtonText="Delete"
            />
        </>
    );
};

export default BadgeEditForm;
