import { gigStatusOptions } from "../../api/models/gig";
import TableChipCell from "../Table/TableChipCell";

export type GigStatusCellProps = {
    gigStatusId: number
}

const GigStatusCell = ({
    gigStatusId,
}: GigStatusCellProps) => {
    const option = gigStatusOptions.find(_ => _.value === gigStatusId);

    return (
        <TableChipCell labels={[option?.label || ""]} />
    );
};

export default GigStatusCell;