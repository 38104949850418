import { Table as MuiTable, TableHead, TableBody, TableContainer, TablePagination, TableRow } from "@mui/material";
import { SortOrder } from "../../api/common/sortOrder";
import Loader from "../Loader";
import { TableHeaderCellWithSort, TableHeaderCell } from "../Table";

export type StaticTableColumnConfig = {
    label: string
    isSortable: false
    center?: boolean
}

export type SortableTableColumnConfig = Omit<StaticTableColumnConfig, "isSortable"> & {
    key?: string
    isSortable: true
}

export type TableHeaderConfig = SortableTableColumnConfig | StaticTableColumnConfig;

export type TableProps<T extends { id: string }> = {
    headers: TableHeaderConfig[]
    data?: T[],
    renderRow: (item: T) => JSX.Element
    orderBy?: string
    sortOrder?: SortOrder
    totalCount?: number,
    pageSize?: number,
    pageIndex?: number,
    onOrderByChange?: (key: string) => void
    setPageSize: (pageSize: number) => void
    setPageIndex: (pageIndex: number) => void
    isLoading: boolean
    rowHeight?: string
}

const Table = <T extends { id: string }>({
    headers,
    data,
    renderRow,
    orderBy,
    sortOrder,
    onOrderByChange,
    totalCount = 0,
    pageSize = 10,
    pageIndex = 0,
    setPageSize,
    setPageIndex,
    isLoading,
    rowHeight
}: TableProps<T>) => {
    if (isLoading || !data) return (
        <Loader />
    );

    return (
        <>
            <TableContainer>
                <MuiTable>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                header.isSortable ? (
                                    <TableHeaderCellWithSort
                                        key={header.label}
                                        id={header.key || header.label}
                                        label={header.label}
                                        orderBy={orderBy}
                                        sortOrder={sortOrder}
                                        onOrderByClick={onOrderByChange}
                                        center={header.center}
                                    />
                                ) : (
                                    <TableHeaderCell
                                        key={index}
                                        label={header.label}
                                        center={header.center}
                                    />
                                )
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(item => (
                            <TableRow key={item.id} sx={{
                                borderBottomColor: "#96CCFF",
                                borderCollapse: "separate",
                                borderSpacing: "0px 4px",
                                height: rowHeight,
                                wordBreak: "break-word",
                            }}>
                                {renderRow(item)}
                            </TableRow>
                        ))}
                    </TableBody>
                </MuiTable>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(_, page) => setPageIndex(page)}
                onRowsPerPageChange={event => setPageSize(parseInt(event.target.value))}
            />
        </>
    );
};

export default Table;