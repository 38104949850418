import { useDispute } from "../api/dispute";
import DisputeViewPage from "../components/DisputeView/DisputeViewPage";
import FormContainer from "../components/FormContainer";
import { useDisputeId } from "../Routes";

const DisputePage = () => {
    const disputeId = useDisputeId();
    const dispute = useDispute(disputeId);
    
    return (
        <FormContainer>
            <DisputeViewPage
                dispute={dispute.dispute}
            />
        </FormContainer >
    );
};

export default DisputePage;