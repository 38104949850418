import { Box, Typography } from "@mui/material";
import DisputesTableWithSearch from "../components/DisputesTable/";
import useIsLargeScreen from "../hooks/useIsLargeScreen";

const DisputesListPage = () => {
    const isLargeScreen = useIsLargeScreen();

    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 )}}>
                <Typography component="h1" variant="h4" gutterBottom>Disputes</Typography>
                <DisputesTableWithSearch />
            </Box>
        </>
    );
};

export default DisputesListPage;