import { Alert } from "@mui/material";

export type FormErrorsProps = {
    messages?: string[]
}

const FormErrors = ({
    messages = [],
}: FormErrorsProps) => {
    return (
        <>
            {messages.map((message, index) => (
                <Alert key={index} severity="error">
                    {message}
                </Alert>
            ))}
        </>
    );
};

export default FormErrors;