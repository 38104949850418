import { useReport } from "../api/report";
import ReportViewPage from "../components/ReportView/ReportViewPage";
import FormContainer from "../components/FormContainer";
import { useReportId } from "../Routes";

const ReportPage = () => {
    const reportId = useReportId();
    const report = useReport(reportId);

    return (
        <FormContainer>
            <ReportViewPage
                report={report.report}
            />
        </FormContainer >
    );
};

export default ReportPage;