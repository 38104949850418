import { Typography } from "@mui/material";

export const FourOFourPage = () => {
    return (
        <>
            <Typography variant="h4" component="h1" className="text-center text-primary font-bold md:text-[12rem] mt-8">404</Typography>
            <Typography>Sorry we couldn't find what you are looking for.</Typography>
        </>
    );
};

export default FourOFourPage;