import { Chip, TableCell, useTheme } from "@mui/material";

export type TableChipCellProps = {
    labels?: string[]
    link?: string
    color?: string;
}

const TableChipCell = ({
    labels,
    link,
    color,
}: TableChipCellProps) => {
    const theme = useTheme();

    return (
        <TableCell align="left">
            <div className="flex flex-row">
                {labels?.filter(label => label).map(label => 
                    <Chip 
                        key={label}
                        label={label} 
                        sx={{
                            backgroundColor: color || theme.palette.primary.light,
                            fontWeight: 700,
                            cursor: link ? "cursor" : "default",
                            marginRight: 1
                        }} 
                    />
                )}
            </div>
        </TableCell>
    );
};

export default TableChipCell;