import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";

export type VisibilityIconProps = {
    title: string,
    onClick: () => void
    isVisible: boolean
}

const VisibleIcon = ({
    title,
    onClick,
    isVisible
}: VisibilityIconProps) => {
    return (
        <Tooltip title={title} placement="top" sx={{ backgroundColor: "transparent" }}>
            <IconButton onClick={onClick}>
                {isVisible ? (
                    <VisibilityOffIcon sx={{ color: "black", paddingBottom: 0 }} fontSize="small" />
                ) : (
                    <VisibilityIcon sx={{ color: "black", paddingBottom: 0 }} fontSize="small" />
                )}
            </IconButton>
        </Tooltip>
    );
};

export default VisibleIcon;