import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type ReportResolveFormValues = {
    resolutionMessage: string;
}

const reportResolveValidationSchema = yup.object({
    resolutionMessage: yup
        .string()
        .max(1000, "Resolution message cannot be longer than 1000 characters.")
        .required("Resolution message is required."),
});


export const useResolveReportForm = () => {
    return useForm<ReportResolveFormValues>({
        resolver: yupResolver(reportResolveValidationSchema),
        mode: "onSubmit",
        defaultValues: {
            resolutionMessage: ""
        }
    });
};