import { useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { Box, FormHelperText, MenuItem, Select, useTheme } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";

import FormTextInput from "../FormTextInput";
import ApiError from "../../api/common/apiError";
import FormErrors from "../FormErrors";
import ConfirmDialog from "../ConfirmDialogBox";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../../Routes";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { BadgeCreateFormValues, useBadgeForm } from "./BadgeCreateFormValues";
import { useOrganizationConfig } from "../../api/organization";
import { badgeTypeOptions } from "../../api/models/badge";

type BadgeCreateFormProps = {
    onSubmit: (values: BadgeCreateFormValues) => Promise<GigApiFetcherResponse<string>>
    isSubmitting: boolean
}

const BadgeCreateForm = ({
    onSubmit,
    isSubmitting
}: BadgeCreateFormProps) => {
    const { giggedClientTerminology, talentTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.badges.index);
        }
    });

    const methods = useBadgeForm();

    const {
        formState: { isDirty }
    } = methods;

    const handleSubmit = async (values: BadgeCreateFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.badges.index);
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    noValidate
                >
                    <FormCard onClose={handleCloseButton} title="Create Badge">
                        <Box sx={{
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            }
                        }}>
                            <FormTextInput name="name" label="Name" disabled={isSubmitting} required />
                            <FormTextInput name="description" label="Description" disabled={isSubmitting} required />
                            <FormTextInput name="imageFileName" label="Image file name" disabled={isSubmitting} required />
                            <Box className="space-y-2 flex flex-col">
                                <Controller
                                    name="typeId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            variant="outlined"
                                            color="secondary"
                                            disabled={isSubmitting}
                                            displayEmpty
                                            error={!!methods.formState.errors.typeId}
                                            required
                                        >
                                            <MenuItem disabled >Select badge type</MenuItem>
                                            {
                                                badgeTypeOptions.length > 0 && badgeTypeOptions(talentTerminology, giggedClientTerminology).map(option => (
                                                    <MenuItem key={option?.value} value={option?.value}>{option?.label}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    )}
                                />
                                {methods.formState.errors.typeId && <FormHelperText className="!ml-4" error={!!methods.formState.errors.typeId}>{methods.formState.errors.typeId.message}</FormHelperText>}

                            </Box>
                            <FormErrors messages={submissionError?.userMessages} />
                            <Box sx={
                                isLargeScreen ?
                                    { display: "flex", justifyContent: "flex-end" } :
                                    { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end" }
                            }>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    startIcon={<SaveOutlinedIcon sx={isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                    loading={isSubmitting}
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                >
                                    Save Changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider >
            <ConfirmDialog
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default BadgeCreateForm;