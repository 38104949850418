import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useAgencies } from "../../api/agencies";
import formatDateString from "../../utils/formatDateString";
import { useAppPaths } from "../../Routes";
import { useOrganizationConfig } from "../../api/organization";

export type AgenciesTableProps = {
    searchTerm?: string
}

const AgenciesTable = ({ searchTerm }: AgenciesTableProps) => {
    const agencies = useAgencies();
    const appPaths = useAppPaths();
    const { talentTerminology } = useOrganizationConfig();

    const setSearchTerm = agencies.setSearchTerm;

    const handleOrderByChange = (property: string) => {
        const isAsc = agencies.orderBy === property && agencies.sortOrder === "asc";
        agencies.setSortOrder(isAsc ? "desc" : "asc");
        agencies.setOrderBy(property);
    };

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    if (agencies.isLoading || !agencies.agencies) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "Name",
                        label: "Name",
                        isSortable: true,
                    },
                    {
                        key: "TalentCount",
                        label: `${talentTerminology} count`,
                        isSortable: true,
                    },
                    {
                        key: "CreatedAt",
                        label: "Date created",
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false
                    }]}
                data={agencies.agencies}
                renderRow={agency => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{agency.id}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{agency.name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{agency.talentCount}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateString(agency.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.agencies.edit(agency.id)}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={agencies.setPageIndex}
                setPageSize={agencies.setPageSize}
                pageSize={agencies.pageSize}
                pageIndex={agencies.pageIndex}
                totalCount={agencies.totalCount}
                orderBy={agencies.orderBy}
                sortOrder={agencies.sortOrder}
                isLoading={agencies.isLoading}
            />
        </TableCard>
    );
};

export default AgenciesTable;
