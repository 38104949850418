import { Box, Chip, SxProps, Theme, useTheme } from "@mui/material";

export type DeletableChip = {
    label: string
    visible: boolean
    onDelete: () => void
}

export type DeleteChipsProps = {
    chips: DeletableChip[]
    sx?: SxProps<Theme>
    deleteIcon?: JSX.Element
}

export const DeletableChips = ({
    chips,
    sx,
    deleteIcon,
}: DeleteChipsProps) => {
    const theme = useTheme();

    return (
        <Box sx={{
            whiteSpace: "nowrap",
            overflowX: "auto",
            "& > *:not(:first-of-type)": {
                marginLeft: theme.spacing(1),
            }
        }}>
            {
                chips.map(chip => (
                    chip.visible && (
                        <Chip
                            key={chip.label}
                            label={chip.label}
                            onDelete={chip.onDelete}
                            variant="filled"
                            sx={sx}
                            deleteIcon={deleteIcon}
                        />
                    )
                ))
            }
        </Box >
    );
};

export default DeletableChips;