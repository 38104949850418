import { InsertDriveFile } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";

import FileDropZone from "../FileDropZone";
import FormErrors from "../FormErrors";
import FormInputLabel from "../FormInputLabel";

export type FormFileUploadInputProps = {
    label?: string
    helpText?: string
    inputId: string
    dataUrl?: string
    isImage: boolean
    fileSizeDescription?: string,
    fileName?: string
    error?: string
    isDirty: boolean
    onLoadFile: (file: File) => void
    required?: boolean
    disabled?: boolean
    tooltip?: string
}

const FormFileUploadInput = ({
    label,
    helpText,
    inputId,
    dataUrl,
    isImage,
    fileSizeDescription,
    fileName,
    error,
    isDirty,
    onLoadFile,
    required,
    disabled,
    tooltip
}: FormFileUploadInputProps) => {
    return (
        <div>
            {label && (
                <FormInputLabel required={required} tooltipText={tooltip}>{label}</FormInputLabel>
            )}
            <div className="space-y-3">
                {helpText && 
                    <Typography> {helpText} </Typography>
                }
                <FileDropZone
                    inputId={inputId}
                    onFileDrop={onLoadFile}
                    disabled={disabled}
                    className="mb-4"
                />
                {error &&
                    <div className="mb-6">
                        <FormErrors messages={[error]} />
                    </div>
                }
                {dataUrl && (
                    <div className="flex justify-center sm:block">
                        <div className="w-40 mb-4">
                            {isImage ? (
                                <img src={dataUrl} className={clsx("max-h-40 mb-4", !isDirty && "opacity-50")} />
                            ) : (
                                <div className="flex justify-center items-center h-40 w-40 bg-white rounded-md mb-4">
                                    <InsertDriveFile className="w-20" />
                                </div>
                            )}
                            {isDirty ? (
                                <>
                                    <p className="font-bold break-words">{fileName}</p>
                                    <p>File size: {fileSizeDescription}</p>
                                </>
                            ) : (
                                <p className="font-bold opacity-50">Current</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormFileUploadInput;
