import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { ClientCreateFormValues, useClientForm } from "./ClientCreateFormValues";
import FormTextInput from "../FormTextInput";
import FormChipInput from "../FormChipInput";
import ApiError from "../../api/common/apiError";
import FormErrors from "../FormErrors";
import { useAppPaths } from "../../Routes";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import ConfirmDialog from "../ConfirmDialogBox";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormCard from "../FormCard";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { useOrganizationConfig } from "../../api/organization";

type ClientCreateFormProps = {
    onSubmit: (values: ClientCreateFormValues) => Promise<GigApiFetcherResponse<string>>;
    isSubmitting: boolean
}

const ClientCreateForm = ({
    onSubmit,
    isSubmitting
}: ClientCreateFormProps) => {
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const appPaths = useAppPaths();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const methods = useClientForm();
    const {
        formState: { isDirty, isSubmitting: isFormSubmitting },
        getValues,
        handleSubmit,
    } = methods;
    const navigate = useNavigate();
    const { giggedClientTerminology } = useOrganizationConfig();

    const handleCreateClient = async () => {
        setSubmissionError(undefined);

        const response = await onSubmit(getValues());

        if (!response.success) setSubmissionError(response.error);
    };
    
    const [openSaveDialog, saveDialogProps] = useConfirmDialogBoxState({
        onConfirm: handleCreateClient,
    });

    const [openCancelDialog, cancelDialogProps] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.clients.index);
        }
    });
    
    const handleSaveClick = () => {
        if (methods.getValues().userEmails.length !== 0 ) {
            openSaveDialog();
        }
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.clients.index);
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(handleSaveClick)}
                    noValidate
                >
                    <FormCard
                        onClose={handleCloseButton}
                        title={`Create ${giggedClientTerminology}`}
                    >
                        <Box sx={{
                            "> *:not(:last-child)": {
                                marginBottom: theme.spacing(2),
                            },
                        }}>
                            <FormTextInput
                                name="name"
                                label={`${giggedClientTerminology} name`}
                                disabled={isSubmitting}
                                required
                            />
                            <FormChipInput
                                name="userEmails"
                                label="Add users by email"
                                placeholder="Type email address then press enter"
                                required
                            />
                            <FormErrors
                                messages={submissionError?.userMessages}
                            />
                            <Box sx={
                                isLargeScreen ?
                                    { display: "flex", justifyContent: "flex-end", marginTop: "2rem" } :
                                    { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                            }>
                                <LoadingButton
                                    sx={
                                        isLargeScreen ?
                                            { marginBottom: 0 } :
                                            { marginBottom: theme.spacing(2) }
                                    }
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    loading={isSubmitting}
                                    disabled={isFormSubmitting}
                                    startIcon={<SaveOutlinedIcon sx={ isSubmitting || isFormSubmitting ? { opacity: "26%" } : { opacity: "100%" } } />}
                                >
                                    Save changes
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider >
            <ConfirmDialog
                {...saveDialogProps}
                message={`An email invitation will be sent to all users added to this ${giggedClientTerminology.toLowerCase()}.`}
                isProcessingConfirm={isSubmitting}
                sx={{ maxWidth: "20rem" }}
            />
            <ConfirmDialog
                {...cancelDialogProps}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default ClientCreateForm;