import { Divider, useTheme } from "@mui/material";

const TitleUnderline = () => {
    const theme = useTheme();

    return (
        <Divider sx={{ borderBottom: `3px solid ${theme.palette.primary.main}`}} />
    );
};

export default TitleUnderline;