import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "../common/giggedApiSWR";
import { ApiDisputeStatisticsDto } from "../models/dashboardStatistics/disputeStatistics";

export type UseDisputeStatisticsReturn =
    UseGiggedApiSWRReturn<ApiDisputeStatisticsDto> &
    {
        statistics?: ApiDisputeStatisticsDto
        isLoading: boolean
    }

export const useDisputeStatistics = (): UseDisputeStatisticsReturn => {

    const url = "api/admin-dashboard/statistics/disputes";

    const apiCall = useGiggedApiSWR<ApiDisputeStatisticsDto>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        statistics: apiCall.data,
        isLoading: apiCall.isLoading
    };
};