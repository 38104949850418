import { Controller, FormProvider } from "react-hook-form";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { TalentDetailsDto } from "../../api/models/talent";
import { TalentBadgesFormValues, defaultTalentBadgesFormValues, useTalentBadgesForm } from "./TalentBadgesFormValues";
import FormCard from "../FormCard";
import { Box, Button, MenuItem, Select, useTheme } from "@mui/material";
import { useBadges } from "../../api/badges";
import FormInputLabel from "../FormInputLabel";
import { LoadingButton } from "@mui/lab";
import DeletableChips from "../DeletableChips/DeletableChips";
import { useOrganizationConfig } from "../../api/organization";
import { talentTooltips } from "../../tooltipsContent";
import { badgeTypes } from "../../api/models/badge";

type TalentBadgesFormProps = {
    onSubmit: (values: TalentBadgesFormValues) => Promise<GigApiFetcherResponse<void>>;
    onDelete: (badgeId: string) => Promise<GigApiFetcherResponse<void>>;
    initialValues: TalentDetailsDto | undefined;
    isSubmitting: boolean;
}

const TalentBadgesForm = ({
    onSubmit,
    onDelete,
    initialValues,
    isSubmitting
}: TalentBadgesFormProps) => {
    const theme = useTheme();
    const { talentTerminology } = useOrganizationConfig();

    const { data: badges, isLoading: isLoadingBadges } = useBadges({
        initialPageSize: 100,
        badgeTypeIds: [badgeTypes.talent]
    });
    const methods = useTalentBadgesForm(defaultTalentBadgesFormValues);

    const { formState: { isDirty }, reset } = methods;

    const badgeChips = initialValues?.badges?.map(badge => ({
        label: badge.badgeName,
        visible: true,
        onDelete: () => onDelete(badge.badgeId)
    })) ?? [];

    const currentBadgeIdList = initialValues?.badges?.map(badge => badge.badgeId) ?? [];

    const handleAddBadge = async (values: TalentBadgesFormValues) => {
        const response = await onSubmit(values);

        if (response.success) {
            reset();
        }
    };

    return (
        <FormProvider {...methods}>
            <FormCard title="Badges" withoutTitleUnderline width="100%">
                <form onSubmit={methods.handleSubmit(handleAddBadge)}>
                    <Box className="space-y-2">
                        <div className="flex-1">
                            <FormInputLabel tooltipText={talentTooltips.talentBadge(talentTerminology)}>Select a badge</FormInputLabel>
                            <Box className="flex items-center gap-4">
                                {isLoadingBadges ? "Loading..." : (
                                    <Controller
                                        name="badgeId"
                                        control={methods.control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                value={value}
                                                onChange={onChange}
                                                variant="outlined"
                                                color="secondary"
                                                disabled={isSubmitting || isLoadingBadges}
                                                className="w-full"
                                            >
                                                <MenuItem disabled value="None">Select a badge</MenuItem>
                                                {
                                                    badges.length > 0 && badges.map(option => (
                                                        <MenuItem key={option?.badgeId} value={option?.badgeId} disabled={currentBadgeIdList.includes(option.badgeId)}>{option?.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                    />
                                )}
                                <Button disabled={!isDirty || isSubmitting} type="submit" color="secondary" variant="outlined">Add badge</Button>
                            </Box>
                        </div>
                    </Box>
                </form>
                <Box className="mt-6 space-y-4">
                    <DeletableChips
                        sx={{
                            color: `${theme.palette.tertiary.main} !important`,
                            backgroundColor: `${theme.palette.primary.dark} !important`,
                            maxWidth: "18rem",
                        }}
                        chips={badgeChips} />
                </Box>
            </FormCard>
        </FormProvider>
    );
};

export default TalentBadgesForm;