import { ListItemText, MenuItem } from "@mui/material";
import Checkbox from "../Checkbox";

export type FilterMenuOptionGroupOption = {
    label: string
    value: boolean
    onChange: (value: boolean) => void
}

export type FilterMenuOptionGroupProps = {
    label: string
    options: FilterMenuOptionGroupOption[]
}

const FilterMenuOptionGroup = ({
    label,
    options,
}: FilterMenuOptionGroupProps) => {

    return (
        <>
            <ListItemText sx={{ paddingLeft: 2 }}>
                {label}
            </ListItemText>
            {options.map(option => (
                <MenuItem
                    key={option.label}
                    sx={{ paddingLeft: 4 }}
                >
                    <Checkbox
                        label={option.label}
                        value={option.value}
                        onChange={option.onChange}
                    />
                </MenuItem>
            ))}
        </>
    );
};

export default FilterMenuOptionGroup;