import React from "react";
import { LoginRequest } from "./useLoginRequest";

export type AuthContextType = {
    loginRequest: LoginRequest
};

export const AuthContext = React.createContext<AuthContextType>({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    loginRequest: undefined!
});

export const useAuthContext: () => AuthContextType = () => React.useContext(AuthContext);

export default AuthContext;