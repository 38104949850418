import { Box, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import formatDateTimeString from "../utils/formatDateTimeString";

export type CreatedByDisplayProps = {
    createdBy?: string
    createdAt: string
    updatedBy?: string
    updatedAt?: string
}

const CreatedByDisplay = ({
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
}: CreatedByDisplayProps) => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
            }}
        >
            <CalendarMonthIcon sx={{ marginRight: 1 }} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography component="span">
                    Created {createdBy && `by ${createdBy}`} on {formatDateTimeString(createdAt)}
                </Typography>
                {updatedAt && (
                    <Typography component="span">
                        Last updated {updatedBy && `by ${updatedBy}`} on {formatDateTimeString(updatedAt)}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default CreatedByDisplay;