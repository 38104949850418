import { FieldError } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useState } from "react";
import { fetchClients } from "../api/clients";
import FormSearchDropdown, { FormSearchDropdownOption, LoadOptionsCallback } from "./FormSearchDropdown/FormSearchDropdown";
import useAccessToken from "../auth/useAccessToken";

export type GiggedClientInputProps = {
    tooltip?: string
    value: string
    onChange: (value: string, option: FormSearchDropdownOption | null) => void
    isSubmitting: boolean
    error?: FieldError
}

export const GiggedClientInput = ({
    tooltip,
    value,
    onChange,
    isSubmitting,
    error,
}: GiggedClientInputProps) => {
    const accessToken = useAccessToken();

    const [allGiggedClientOptions, setAllGiggedClientOptions] = useState<FormSearchDropdownOption[]>([]);

    const fetchGiggedClientOptions = async (searchQuery: string) => {
        const categoriesResponse = await fetchClients(searchQuery, accessToken);

        return categoriesResponse
            .map(giggedClient => ({
                value: giggedClient.id,
                label: giggedClient.name,
            }));
    };

    useEffect(() => {
        if (!accessToken) return;

        const loadAllCategories = async () => {
            const fetchedGiggedClientOptions = await fetchGiggedClientOptions("");
            setAllGiggedClientOptions(fetchedGiggedClientOptions);
        };

        loadAllCategories();
    }, [accessToken]);

    const loadGiggedClientOptions = (inputValue: string, callback: LoadOptionsCallback) => {
        fetchGiggedClientOptions(inputValue)
            .then(options => callback(options));
    };

    const debouncedLoadSkillOptions = useDebouncedCallback(loadGiggedClientOptions, 700);

    return (
        <>
            <FormSearchDropdown
                tooltip={tooltip}
                value={value}
                loadOptions={debouncedLoadSkillOptions}
                onChange={onChange}
                defaultOptions={allGiggedClientOptions}
                disabled={isSubmitting}
                required={true}
                error={error}
            />
        </>
    );
};