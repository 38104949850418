import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import defaultIfUndefined from "../../utils/defaultIfUndefined";

export type BadgeEditFormValues = {
    name: string,
    description: string,
    imageFileName: string;
    typeId: number
};

export const useEditBadgeForm = (initialValues?: Partial<BadgeEditFormValues>) => {
    return useForm<BadgeEditFormValues>({
        resolver: yupResolver(badgeValidationSchema),
        mode: "onSubmit",
        defaultValues: {
            ...initialValues,
            typeId: defaultIfUndefined(initialValues?.typeId, -1)
        },
    });
};

const badgeValidationSchema = yup
    .object({
        name: yup.string().required("Name is required."),
        description: yup.string().required("Description is required."),
        imageFileName: yup.string()
            .required("Image file name is required."),
    })
    .required();

