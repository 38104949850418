import { useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import { Box, useTheme, MenuItem, Select } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { LoadingButton } from "@mui/lab";

import FormTextInput from "../FormTextInput";
import ApiError from "../../api/common/apiError";
import FormErrors from "../FormErrors";
import ConfirmDialog from "../ConfirmDialogBox";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../../Routes";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import FormCard from "../FormCard";
import FormDateInput from "../FormDateInput";
import FormTextAreaInput from "../FormTextAreaInput";
import ToggleButton from "../ToggleButton";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { GigCreateFormValues, useGigCreateForm } from "./GigCreateFormValues";
import FormInputLabel from "../FormInputLabel";
import { GiggedClientInput } from "../GiggedClientDropdown";
import GigSkillsInput from "../GigSkillsInput";
import { useOrganizationConfig } from "../../api/organization";
import { gigStatuses, expectedDurationOptions, deliveryTypeOptions } from "../../api/models/gig";
import IndustrialExperienceDropdown from "../IndustrialExperienceDropdown";
import FormDropdown, { FormDropdownOption } from "../FormDropdown";
import { fetchLocationOptions } from "../../api/location";
import useAccessToken from "../../auth/useAccessToken";
import LanguageDropdown from "../LanguageDropdown";
import FormDateTimeInput from "../FormDateTimeInput";

type GigCreateFormProps = {
    onSubmit: (values: GigCreateFormValues) => Promise<GigApiFetcherResponse<string>>
    isSubmitting: boolean
}

const GigCreateForm = ({
    onSubmit,
    isSubmitting
}: GigCreateFormProps) => {
    const accessToken = useAccessToken();
    const { giggedClientTerminology, gigTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const [allLocationOptions, setAllLocationOptions] = useState<FormDropdownOption<string>[]>([]);
    const [isLoadingLocationOptions, setIsLoadingLocationOptions] = useState(false);
    const [selectedLocationLocalities, setSelectedLocationLocalities] = useState<FormDropdownOption<string>[]>([]);
    const [selectedLocationId, setSelectedLocationId] = useState<string | null>();
    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.gigs.index);
        }
    });

    const methods = useGigCreateForm();
    const { formState: { isDirty, errors, submitCount }, watch, control, handleSubmit } = methods;
    const { skills } = watch();
    
    const { append: appendSkill, remove: removeSkill } = useFieldArray<GigCreateFormValues, "skills">({
        control: control,
        name: "skills",
        keyName: "id",
    });
    
    const isDesignFeeRequired = methods.watch("isDesignFeeTypeRequired");
    const isConsultancyFeeRequired = methods.watch("isConsultancyFeeTypeRequired");
    const isPrepAndEvalFeeReqired = methods.watch("isPrepAndEvalFeeTypeRequired");

    useEffect(function resetFeeTypeFieldsWhenFeeIsFalse() {
        if (!isDesignFeeRequired) methods.setValue("designFeeTypeHours", undefined);
        if (!isConsultancyFeeRequired) methods.setValue("consultancyFeeTypeHours", undefined);
        if (!isPrepAndEvalFeeReqired) methods.setValue("prepAndEvalFeeTypeHours", undefined);
    }, [isDesignFeeRequired, isConsultancyFeeRequired, isPrepAndEvalFeeReqired]);

    const handleGigSubmit = async (values: GigCreateFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit({
            ...values,
            gigStatusId: gigStatuses.posted
        });

        if (!response.success) setSubmissionError(response.error);

        return response;
    };

    const handleSaveAsDraft = async (values: GigCreateFormValues) => {
        setSubmissionError(undefined);

        const isValid = await methods.trigger();

        if (isValid) {
            const response = await onSubmit({
                ...values,
                gigStatusId: gigStatuses.draft
            });

            if (!response.success) setSubmissionError(response.error);

            return response;
        }
    };

    const handleCloseButton = () => {
        if (isDirty) {
            openCancelDialog();
        } else {
            navigate(appPaths.gigs.index);
        }
    };

    useEffect(function setLocationOptions() {
        if (!accessToken) return;

        const loadAllLocations = async () => {
            setIsLoadingLocationOptions(true);
            try {
                const fetchedLocationOptions = await fetchLocationOptions("", accessToken);
                setAllLocationOptions(fetchedLocationOptions as FormDropdownOption<string>[]);
            }
            finally {
                setIsLoadingLocationOptions(false);
            }
        };

        loadAllLocations();
    }, [accessToken]);

    useEffect(function setLocalitiesDropdown() {
        setSelectedLocationId(methods.watch("locationId"));
        const selectedLocation = allLocationOptions.find(location => location.value === selectedLocationId);

        if (selectedLocation && selectedLocation.localities) {
            setSelectedLocationLocalities(selectedLocation.localities);
        } else {
            setSelectedLocationLocalities([]);
        }

    }, [allLocationOptions, methods.watch("locationId"), selectedLocationId]);

    useEffect(function resetLocalityOnLocationChange() {
        if (selectedLocationLocalities.length < 1 || isLoadingLocationOptions) return;
        methods.setValue("localityId", "");
    }, [selectedLocationId]);

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={handleSubmit(handleGigSubmit)}
                    noValidate
                >
                    <FormCard onClose={handleCloseButton} title={`Create ${gigTerminology}`}>
                        <Box className="space-y-4">
                            <Box className="space-y-2">
                                <FormInputLabel required>{"Client name"}</FormInputLabel>
                                <FormTextInput
                                    required
                                    name="title"
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <GigSkillsInput
                                addSkill={appendSkill}
                                removeSkill={removeSkill}
                                skills={skills || []}
                                error={errors.skills}
                                showError={submitCount > 0}
                                required
                            />
                            <Box className="space-y-2 flex flex-col">
                                <FormInputLabel required>{"Select industry of the client"}</FormInputLabel>
                                <Controller
                                    name="industryId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <IndustrialExperienceDropdown
                                            required
                                            error={methods.formState.errors.industryId}
                                            onChange={onChange}
                                            value={value || ""}
                                        />
                                    )}
                                />
                            </Box>
                            <Controller
                                name="deliveryTypeId"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDropdown
                                        required
                                        label="What is the required delivery format for this event?"
                                        options={deliveryTypeOptions}
                                        error={methods.formState.errors.deliveryTypeId}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            <Box className="space-y-2 flex flex-col mt-4">
                                <FormInputLabel required>{`Language this ${gigTerminology.toLowerCase()} is to be delivered in`}</FormInputLabel>
                                <Controller
                                    name="languageId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <LanguageDropdown
                                            required
                                            error={methods.formState.errors.languageId}
                                            onChange={onChange}
                                            value={value || ""}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className="space-y-2 flex flex-col mt-4">
                                <FormInputLabel required>{giggedClientTerminology}</FormInputLabel>
                                <Controller
                                    name="giggedClientId"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <GiggedClientInput
                                            error={errors.giggedClientId}
                                            onChange={onChange}
                                            value={value}
                                            isSubmitting={isSubmitting}
                                        />
                                    )}
                                />
                            </Box>
                            <Box className="space-y-2 flex flex-col mt-4">
                                <FormInputLabel required>What are the expected number of participants?</FormInputLabel>
                                <FormTextInput
                                    name="expectedNumberParticipants"
                                    required
                                    type="number"
                                    placeholder="Number of particpants"
                                    inputProps={{ min: 0, max: 100, step: 1 }}
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                />
                            </Box>
                            {allLocationOptions?.length > 0 ? (
                                <Controller
                                    name="locationId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormDropdown
                                            required
                                            label="What country is the client located in?"
                                            value={value || ""}
                                            options={allLocationOptions}
                                            disabled={isLoadingLocationOptions}
                                            onChange={onChange}
                                            error={methods.formState.errors.locationId}
                                            isLoadingOptions={allLocationOptions.length < 1 || isLoadingLocationOptions}
                                        />
                                    )}
                                />
                            ) : <div>Loading...</div>}
                            {selectedLocationLocalities?.length > 0 && (
                                <Controller
                                    name="localityId"
                                    control={methods.control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormDropdown
                                            required
                                            label="City"
                                            value={value || ""}
                                            options={selectedLocationLocalities}
                                            disabled={selectedLocationLocalities.length < 1 || isLoadingLocationOptions}
                                            onChange={onChange}
                                            error={methods.formState.errors.localityId}
                                            isLoadingOptions={selectedLocationLocalities.length < 1}
                                        />
                                    )}
                                />
                            )}
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormDateTimeInput
                                        name="startDate"
                                        label="Start date and time"
                                        value={value}
                                        onChange={onChange}
                                        minDate={new Date()}
                                        required
                                    />
                                )}
                            />
                            <Box className="flex flex-col flex-1 space-y-2">
                                <FormInputLabel required>Duration</FormInputLabel>
                                <Controller
                                    name="expectedDurationId"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            value={value}
                                            onChange={onChange}
                                            variant="outlined"
                                            color="secondary"
                                        >
                                            {expectedDurationOptions.map(option => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Box>
                            <FormTextAreaInput
                                name="description"
                                label={"Program objectives"}
                                disabled={isSubmitting}
                                sx={{ marginBottom: theme.spacing(2) }}
                            />
                            <Controller
                                name="isDesignFeeTypeRequired"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label="Does the event require customisation of content and/or addition of new content?"
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            {isDesignFeeRequired == true && (
                                <FormTextInput
                                    required={isDesignFeeRequired}
                                    label="No. of design hours"
                                    placeholder="Number of hours"
                                    type="number"
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    {...methods.register("designFeeTypeHours")}
                                />
                            )}
                            <Controller
                                name="isConsultancyFeeTypeRequired"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label="Does the event require time spent using consultancy expertise to assist the client in achieveing the value or impact required?"
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            {isConsultancyFeeRequired == true && (
                                <FormTextInput
                                    required={isConsultancyFeeRequired}
                                    label="No. of consultancy hours"
                                    placeholder="Number of hours"
                                    type="number"
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    {...methods.register("consultancyFeeTypeHours")}
                                />
                            )}
                            <Controller
                                name="isPrepAndEvalFeeTypeRequired"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label="Does the event require additional time spent in preparation or evaluation to achieve a successful outcome? (prep and eval)?"
                                        onChange={onChange}
                                        checked={value}
                                    />

                                )}
                            />
                            {isPrepAndEvalFeeReqired == true && (
                                <FormTextInput
                                    required={isPrepAndEvalFeeReqired}
                                    label="No. of prep and eval hours"
                                    placeholder="Number of hours"
                                    type="number"
                                    onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                                    {...methods.register("prepAndEvalFeeTypeHours")}
                                />
                            )}
                            <Box className="space-y-2">
                                <FormInputLabel>Enter opportunity link</FormInputLabel>
                                <FormTextInput
                                    name="opportunityLink"
                                    placeholder="Please provide the URL from D365"
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <FormTextAreaInput
                                name="notes"
                                label="Notes"
                                placeholder="Add any relevant notes"
                                disabled={isSubmitting}
                                rows={7}
                            />
                            <Controller
                                name="hasClientHistory"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label={"Have we worked with this client before?"}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                            <Controller
                                name="isAudienceExecutive"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleButton
                                        label={"Is the audience Senior Executive/C-Suite level?"}
                                        onChange={onChange}
                                        checked={value}
                                    />
                                )}
                            />
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                        <Box sx={
                            isLargeScreen ?
                                { display: "flex", justifyContent: "flex-end", marginTop: "2rem", gap: 2 } :
                                { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", marginTop: "2rem" }
                        }>
                            <LoadingButton
                                disabled={isSubmitting}
                                loading={isSubmitting}
                                type="submit"
                                color="secondary"
                                onClick={() => handleSaveAsDraft(methods.getValues())}
                            >
                                Save as draft
                            </LoadingButton>

                            <LoadingButton
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlinedIcon sx={isSubmitting ? { opacity: "26%" } : { opacity: "100%" }} />}
                                loading={isSubmitting}
                                sx={
                                    isLargeScreen ?
                                        { marginBottom: 0 } :
                                        { marginBottom: theme.spacing(2) }
                                }
                            >
                                Create gig
                            </LoadingButton>
                        </Box>
                    </FormCard>
                </form>
            </FormProvider >
            <ConfirmDialog
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default GigCreateForm;