import { dashboardOverviewTooltips } from "../../tooltipsContent";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardSection from "../../components/Dashboard/DashboardSection";
import { ApiUserStatisticsDto } from "../../api/models/dashboardStatistics/userStatistics";
import DashboardSubSection from "../../components/Dashboard/DashboardSubSection";
import { useOrganizationConfig } from "../../api/organization";

export type DashboardUsersSectionProps = {
    statistics?: ApiUserStatisticsDto
}

const DashboardUsersSection = ({
    statistics
}: DashboardUsersSectionProps) => {
    const { giggedClientTerminologyPlural, talentTerminologyPlural } = useOrganizationConfig();

    return (
        <DashboardSection
            title="Users"
        >
            <div className="flex flex-col space-y-2 w-full">
                <DashboardSubSection
                    title="Totals"
                    tooltip={dashboardOverviewTooltips.usersTotal}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Total"
                            statistic={statistics?.createdTotal || 0}
                        />
                        <DashboardCard
                            title="Admins"
                            statistic={statistics?.usersSplitDto.adminCount || 0}
                        />
                        <DashboardCard
                            title={`${giggedClientTerminologyPlural} & ${talentTerminologyPlural}`}
                            statistic={statistics?.usersSplitDto.giggedClientAndTalentCount || 0}
                        />
                        <DashboardCard
                            title={giggedClientTerminologyPlural}
                            statistic={statistics?.usersSplitDto.giggedClientsCount || 0}
                        />
                        <DashboardCard
                            title={talentTerminologyPlural}
                            statistic={statistics?.usersSplitDto.talentCount || 0}
                        />
                        <DashboardCard
                            title={`${giggedClientTerminologyPlural} %`}
                            statistic={`${statistics?.usersSplitDto.giggedClientsPercentage || 0}%`}
                            tooltip={dashboardOverviewTooltips.usersClientPercentage(giggedClientTerminologyPlural)}
                        />
                        <DashboardCard
                            title={`${talentTerminologyPlural} %`}
                            statistic={`${statistics?.usersSplitDto.talentPercentage || 0}%`}
                            tooltip={dashboardOverviewTooltips.usersTalentPercentage(talentTerminologyPlural)}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title="New users"
                    tooltip={dashboardOverviewTooltips.created("users")}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={`${statistics?.createdLast7Days || 0} (+${statistics?.percentageIncreaseLast7Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={`${statistics?.createdLast14Days || 0} (+${statistics?.percentageIncreaseLast14Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={`${statistics?.createdLast30Days || 0} (+${statistics?.percentageIncreaseLast30Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={`${statistics?.createdLast3Months || 0} (+${statistics?.percentageIncreaseLast3Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={`${statistics?.createdLast6Months || 0} (+${statistics?.percentageIncreaseLast6Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={`${statistics?.createdLast9Months || 0} (+${statistics?.percentageIncreaseLast9Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={`${statistics?.createdLast12Months || 0} (+${statistics?.percentageIncreaseLast12Months || 0}%)`}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title="Active users"
                    tooltip={dashboardOverviewTooltips.lastActive("users")}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={statistics?.activeLast7Days || 0}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={statistics?.activeLast14Days || 0}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={statistics?.activeLast30Days || 0}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={statistics?.activeLast3Months || 0}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={statistics?.activeLast6Months || 0}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={statistics?.activeLast9Months || 0}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={statistics?.activeLast12Months || 0}
                        />
                    </div>
                </DashboardSubSection>
            </div>
        </DashboardSection>
    );
};

export default DashboardUsersSection;