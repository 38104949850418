import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "../common/giggedApiSWR";
import { ApiUserStatisticsDto } from "../models/dashboardStatistics/userStatistics";

export type UseUserStatisticsReturn =
    UseGiggedApiSWRReturn<ApiUserStatisticsDto> &
    {
        statistics?: ApiUserStatisticsDto
        isLoading: boolean
    }

export const useUserStatistics = (): UseUserStatisticsReturn => {

    const url = "api/admin-dashboard/statistics/users";

    const apiCall = useGiggedApiSWR<ApiUserStatisticsDto>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        statistics: apiCall.data,
        isLoading: apiCall.isLoading
    };
};