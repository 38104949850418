const formatDateTimeString = (parseableDateString?: string): string => {
    if (!parseableDateString) return "";

    const dateTimeString = new Date(parseableDateString)
        .toLocaleString(
            ["en-GB"], 
            {   year: "numeric", 
                month: "numeric", 
                day: "numeric", 
                hour: "2-digit", 
                minute: "2-digit",
                hour12: false,
            }
        );
    return `${dateTimeString.split(",")[0]} at ${dateTimeString.split(",")[1]}`;
};

export default formatDateTimeString;