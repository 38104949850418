import { Box } from "@mui/material";
import useIsLargeScreen from "../hooks/useIsLargeScreen";

export type FormContainerProps = {
    children?: JSX.Element | (JSX.Element | undefined)[],
}

const FormContainer = ({
    children,
}: FormContainerProps) => {
    const isLargeScreen = useIsLargeScreen();

    return (
        <Box sx={isLargeScreen ? {
            maxWidth: "650px",
            marginLeft: "auto",
            marginRight: "auto",
        } : {
            maxWidth: "650px",
            marginLeft: "auto",
            marginRight: "auto",
        }
        }>
            {children}
        </Box>
    );
};

export default FormContainer;