import ApiError from "./common/apiError";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { SkillDetailsDto, UpdateSkillDto } from "./models/skills";

export const useUpdateSkill = () => useAuthenticatedGigApiFetcher<UpdateSkillDto, void, { skillId: string, dto: UpdateSkillDto }>("PUT",
    ({ skillId, dto }) => ({
        path: `api/skills/${skillId}`,
        body: dto
    }));

export const useDeleteSkill = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (skillId) => ({
        path: `api/skills/${skillId}`,
    }));

export type UseSkillReturn<TSkillId extends string | undefined> = TSkillId extends undefined ?
    { skill: undefined } :
    {
        skill?: SkillDetailsDto
        isLoading: boolean
        error?: ApiError
        updateSkill: (dto: UpdateSkillDto) => Promise<GigApiFetcherResponse<void>>
        deleteSkill: () => Promise<GigApiFetcherResponse<void>>
        isUpdatingSkill: boolean
        isDeletingSkill: boolean
    }

export const useSkill = <TSkillId extends string | undefined>(skillId: TSkillId): UseSkillReturn<TSkillId> => {
    const url = `api/skills/${skillId}`;

    const apiCall = useGiggedApiSWR<SkillDetailsDto>(url);

    const [updateSkill, isUpdatingSkill] = useUpdateSkill();
    const [deleteSkill, isDeletingSkill] = useDeleteSkill();

    if (skillId === undefined) return { skill: undefined } as UseSkillReturn<TSkillId>;

    return {
        skill: apiCall.data,
        isLoading: apiCall.isLoading,
        error: apiCall.error,
        updateSkill: async (dto: UpdateSkillDto) => {
            const response = await updateSkill({ skillId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        deleteSkill: async () => {
            const response = await deleteSkill(skillId);

            return response;
        },
        isUpdatingSkill,
        isDeletingSkill
    } as UseSkillReturn<TSkillId>;
};