import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react";
import useCurrentUser from "../../hooks/useCurrentUser";
import { SignInButton } from "../SignInButton";
import { SignOutButton } from "./SignOutButton";

const UserMenu = () => {
    const currentUser = useCurrentUser();
    const userFirstInitial = currentUser.userGivenName?.length > 0 && currentUser.userGivenName[0].toUpperCase();

    return (
        <Menu as="div" className="relative">
            <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span className="sr-only">Open user menu</span>
                <span className="h-8 w-8 flex items-center justify-center rounded-full bg-surface">
                    {userFirstInitial}
                </span>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-fit rounded-lg shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hover:bg-none z-50">
                    <Menu.Item>
                        <div>
                            <UnauthenticatedTemplate>
                                <SignInButton />
                            </UnauthenticatedTemplate>
                            <AuthenticatedTemplate>
                                <SignOutButton className="!bg-white" />
                            </AuthenticatedTemplate>
                        </div>
                    </Menu.Item>
                </Menu.Items>
            </Transition >
        </Menu >
    );
};

export default UserMenu;