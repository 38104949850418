import { FormLabel, useTheme } from "@mui/material";
import HelpIcon from "./HelpIcon";

export type FormInputLabelProps = {
    htmlFor?: string
    children: string
    tooltipText?: string
    required?: boolean
}

const FormInputLabel = ({
    htmlFor,
    children,
    tooltipText,
    required
}: FormInputLabelProps) => {
    const theme = useTheme();
    return (
        <FormLabel
            required={required}
            htmlFor={htmlFor}
            sx={{ fontWeight: 700, color: theme.palette.secondary.main, display: "flex", flexDirection: "row", alignItems: "center" }}
        >
            {children}
            {tooltipText && <HelpIcon title={tooltipText} />}
        </FormLabel>
    );
};

export default FormInputLabel;