import { CircularProgress } from "@mui/material";

const Loader = () => {
    return (
        <div className="inset-0 fixed flex items-center justify-center">
            <CircularProgress
            />
        </div>
    );
};

export default Loader;
