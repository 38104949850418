import { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { AgencySummaryDto } from "./models/agencies";
import { mapFromApiAgencySummaryDto } from "../mappers/agency";
import { ApiAgencySummaryDto, CreateAgencyDto } from "./models/api/agencies";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";

export const useCreateAgency = () => useAuthenticatedGigApiFetcher<CreateAgencyDto, string, CreateAgencyDto>("POST",
    (dto) => ({
        path: "api/agencies/",
        body: dto
    }));

export type UseAgenciesReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<ApiAgencySummaryDto>>, "data"> & {
        agencies: AgencySummaryDto[]
        sortOrder: SortOrder
        orderBy: string
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        createAgency: (dto: CreateAgencyDto) => Promise<GigApiFetcherResponse<string>>
        isCreatingAgency: boolean
    };

export const useAgencies = (initialPageSize = 10): UseAgenciesReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("Name");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const [createAgency, isCreatingAgency] = useCreateAgency();

    const url = "api/agencies?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}&`;

    const apiCall = useGiggedApiSWR<GigApiPaginatedData<ApiAgencySummaryDto>>(url);

    const agencies = apiCall?.data ? apiCall.data.items.map(mapFromApiAgencySummaryDto) : [];

    return {
        ...apiCall,
        ...apiCall.data,
        agencies,
        sortOrder,
        orderBy,
        setSearchTerm,
        setSortOrder,
        setOrderBy,
        setPageIndex,
        setPageSize,
        createAgency: async (dto: CreateAgencyDto) => {
            const response = await createAgency(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        isCreatingAgency
    };
};