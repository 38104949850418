import { Box, FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import HelpIcon from "./HelpIcon";

export type ToggleButtonProps = {
    label: string
    onChange: (checked: boolean) => void
    checked: boolean
    tooltip?: string
}

const ToggleButton = ({
    label,
    onChange,
    checked,
    tooltip,
}: ToggleButtonProps) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <FormControlLabel
                control={<Switch size="medium" />}
                label={label}
                onChange={() => onChange(!checked)}
                checked={checked}
                sx={{ marginRight: 0 }}
            />
            {tooltip &&
                <HelpIcon title={tooltip} />
            }
        </Box>
    );
};

export default ToggleButton;