import { Box, Typography } from "@mui/material";
import { useOrganizationConfig } from "../api/organization";
import TalentsTable from "../components/TalentsTable";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import pluralizeString from "../utils/pluralizeString";

const TalentsListPage = () => {
    const isLargeScreen = useIsLargeScreen();
    const { talentTerminology } = useOrganizationConfig();

    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 )}}>
                <Typography component="h1" variant="h4" gutterBottom>{pluralizeString(talentTerminology)}</Typography>
                <TalentsTable />
            </Box>
        </>
    );
};

export default TalentsListPage;