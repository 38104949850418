import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton, TableCell } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

import { useBadges } from "../../api/badges";
import Loader from "../Loader";
import Table from "../Table";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import formatDateTimeString from "../../utils/formatDateTimeString";
import FilterLinkCell from "../SkillsTable/FilterLinkCell";
import { useAppPaths } from "../../Routes";
import TableChipCell from "../Table/TableChipCell";

export type BadgesTableProps = {
    searchTerm?: string
}

const BadgesTable = ({
    searchTerm,
}: BadgesTableProps) => {
    const badges = useBadges();
    const { talentTerminology, giggedClientTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();

    const handleOrderByChange = (property: string) => {
        const isAsc = badges.orderBy === property && badges.sortOrder === "asc";
        badges.setSortOrder(isAsc ? "desc" : "asc");
        badges.setOrderBy(property);
    };

    const setSearchTerm = badges.setSearchTerm;

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    if (badges.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "Name",
                        label: "Name",
                        isSortable: true,
                    },
                    {
                        key: "TalentCount",
                        label: `${talentTerminology} count`,
                        isSortable: true,
                    },
                    {
                        label: `${giggedClientTerminology} count`,
                        isSortable: false
                    },
                    {
                        label: "Badge type",
                        isSortable: false,
                    },
                    {
                        key: "CreatedAt",
                        label: "Created at",
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false
                    }
                ]}
                data={badges.data.map(item => ({ ...item, id: item.badgeId }))}
                renderRow={badge => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{badge.badgeId}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{badge.name}</TableCell>
                        <FilterLinkCell
                            href={`${appPaths.talents.badge(badge.id)}?badgeName=${badge.name}`}
                            count={badge.type === "Talent" ? badge.talentCount : undefined}
                        />
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{badge.type === "GiggedClient" ? badge.giggedClientCount : ""}</TableCell>
                        <TableChipCell labels={[badge.type === "Talent" ? talentTerminology : giggedClientTerminology]} />
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{formatDateTimeString(badge.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={`/badges/${badge.id}`}>
                                <IconButton aria-label="edit" >
                                    <ModeEditOutlineOutlinedIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleOrderByChange}
                setPageIndex={badges.setPageIndex}
                setPageSize={badges.setPageSize}
                orderBy={badges.orderBy}
                sortOrder={badges.sortOrder}
                pageSize={badges.pageSize}
                pageIndex={badges.pageIndex}
                totalCount={badges.totalCount}
                isLoading={badges.isLoading}
            />
        </TableCard>
    );
};

export default BadgesTable;