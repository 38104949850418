import { IconButton, TableCell } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

import { useDisputes } from "../../api/disputes";
import Table from "../Table";
import Loader from "../Loader";
import TableCard from "../TableCard";
import { useOrganizationConfig } from "../../api/organization";
import { disputeStatusOptions } from "../../api/models/disputes";
import formatDateTimeString from "../../utils/formatDateTimeString";
import { useEffect } from "react";
import { useAppPaths } from "../../Routes";

export type DisputesTableProps = {
    searchTerm?: string
}

const DisputesTable = ({
    searchTerm
}: DisputesTableProps) => {
    const { gigTerminology } = useOrganizationConfig();
    const appPaths = useAppPaths();
    const disputes = useDisputes();
    const { talentTerminology, giggedClientTerminology } = useOrganizationConfig();
    const disputeStatuses = disputeStatusOptions(talentTerminology, giggedClientTerminology);

    const handleSortOrderChange = (property: string) => {
        const isAsc = disputes.orderBy === property && disputes.sortOrder === "asc";
        disputes.setSortOrder(isAsc ? "desc" : "asc");
        disputes.setOrderBy(property);
    };

    const setSearchTerm = disputes.setSearchTerm;

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [setSearchTerm, searchTerm]);

    if (disputes.isLoading) return (
        <Loader />
    );

    return (
        <TableCard>
            <Table
                headers={[
                    {
                        label: "ID",
                        isSortable: false,
                    },
                    {
                        key: "GigTitle",
                        label: `${gigTerminology} title`,
                        isSortable: true,
                    },
                    {
                        key: "Reason",
                        label: "Reason",
                        isSortable: true,
                    },
                    {
                        key: "DisputeStatus",
                        label: "Status",
                        isSortable: true,
                    },
                    {
                        key: "CreatedAt",
                        label: "Created at",
                        isSortable: true,
                    },
                    {
                        label: "",
                        isSortable: false,
                        center: true,
                    },
                ]}
                data={disputes.disputes}
                renderRow={dispute => (
                    <>
                        <TableCell sx={{ fontFamily: "monospace", whiteSpace: "nowrap" }}>{dispute.id}</TableCell>
                        <TableCell>{dispute.gigTitle}</TableCell>
                        <TableCell>{dispute.reason}</TableCell>
                        <TableCell>{disputeStatuses[dispute.disputeStatus].label}</TableCell>
                        <TableCell>{formatDateTimeString(dispute.createdAt)}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                            <Link to={appPaths.disputes.view(dispute.id)}>
                                <IconButton aria-label="view" >
                                    <VisibilityIcon color="secondary" />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </>
                )}
                onOrderByChange={handleSortOrderChange}
                setPageIndex={disputes.setPageIndex}
                setPageSize={disputes.setPageSize}
                pageSize={disputes.pageSize}
                pageIndex={disputes.pageIndex}
                totalCount={disputes.totalCount}
                orderBy={disputes.orderBy}
                sortOrder={disputes.sortOrder}
                isLoading={disputes.isLoading}
            />
        </TableCard>
    );
};

export default DisputesTable;