import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useOrganizationConfig } from "../../api/organization";

export type DisputeResolveFormValues = {
    messageForTalent: string
    messageForGiggedClient: string
    isTalentWinner: "true" | "false" | null
}

const disputeResolveValidationSchema = () => {
    const { talentTerminology, giggedClientTerminology } = useOrganizationConfig();

    return yup
        .object({
            messageForTalent: yup.string().required(`Message to ${talentTerminology.toLowerCase()} is required.`),
            messageForGiggedClient: yup.string().required(`Message to ${giggedClientTerminology.toLowerCase()} is required.`),
            isTalentWinner: yup.string().required("Winning party is required.").nullable()
        }).required();

};

export const useResolveDisputeForm = () => {
    const schema = disputeResolveValidationSchema();
    return useForm<DisputeResolveFormValues>({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        defaultValues: {
            isTalentWinner: null,
            messageForGiggedClient: "",
            messageForTalent: ""
        }
    });
};