import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type AgencyCreateFormValues = {
    name: string
    description?: string
    url?: string
};

export const useCreateAgencyForm = (initialValues?: AgencyCreateFormValues) => {
    const schema = agencyValidationSchema();

    return useForm<AgencyCreateFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: initialValues
    });
};

const agencyValidationSchema = () => {
    return yup
        .object({
            name: yup.string().required("Agency name is required."),
            description: yup.string(),
            url: yup.string()
        })
        .required();
};