import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useBadgeId } from "../Routes";
import { useBadge } from "../api/badge";
import { BadgeCreateFormValues } from "../components/BadgeCreateForm";
import BadgeEditForm from "../components/BadgeEditForm/BadgeEditForm";

const BadgeEditPage = () => {
    const badgeId = useBadgeId();
    const badge = useBadge(badgeId);

    const onSubmit = async (values: BadgeCreateFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await badge.updateBadge({
            ...values,
        });

        if (response.success) {
            successToast("Badge successfully updated.");
        }

        return response;
    };

    const handleDelete = async () => {
        const response = await badge.deleteBadge();

        if (response.success) {
            successToast("Badge successfully deleted.");
        }

        return response;
    };

    if (badge.isLoading || !badge.badge) return <Loader />;

    return (
        <FormContainer>
            <BadgeEditForm
                initialValues={badge.badge}
                onSubmit={onSubmit}
                onDelete={handleDelete}
                isSubmitting={badge.isUpdatingBadge}
                isDeleting={badge.isDeletingBadge}
            />
        </FormContainer>
    );
};

export default BadgeEditPage;
