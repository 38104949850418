import EmailIcon from "@mui/icons-material/Email";
import { Box, Typography } from "@mui/material";

export type EmailDisplayProps = {
    email: string
}

const EmailDisplay = ({
    email
}: EmailDisplayProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <EmailIcon sx={{ marginRight: 1}} />
            <Typography component="span">
                {email}
            </Typography>
        </Box>
    );
};

export default EmailDisplay;