import { useMediaQuery, useTheme } from "@mui/material";

const useIsLargeScreen = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

    return isLargeScreen;
};

export default useIsLargeScreen;
