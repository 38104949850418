import { LoadingButton } from "@mui/lab";
import { Box, Select, MenuItem, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, FormProvider, UseFormReturn } from "react-hook-form";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import ApiError from "../../api/common/apiError";
import { GigApiFetcherResponse } from "../../api/common/fetching";
import { ApiDisputeDetailsDto } from "../../api/models/api/disputes";
import { buildTalentDisputeReasonOptions, buildClientDisputeReasonOptions } from "../../api/models/disputes";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import FormErrors from "../FormErrors";
import FormInputLabel from "../FormInputLabel";
import FormTextInput from "../FormTextInput";
import { DisputeEditFormValues } from "./DisputeEditFormValues";
import { useOrganizationConfig } from "../../api/organization";

export type DisputeEditFormProps = {
    dispute: ApiDisputeDetailsDto
    disputeeIsTalent?: boolean
    loading: boolean
    methods: UseFormReturn<DisputeEditFormValues>
    onSubmit: (values: DisputeEditFormValues) => Promise<GigApiFetcherResponse<void>>
    handleCloseEditDisputeForm: () => void
}

const DisputeEditForm = ({
    dispute,
    disputeeIsTalent,
    loading,
    methods,
    onSubmit,
    handleCloseEditDisputeForm,
}: DisputeEditFormProps) => {
    const [submissionError, setSubmissionError] = useState<ApiError | undefined>();
    const isLargeScreen = useIsLargeScreen();
    const { talentTerminology, giggedClientTerminology }= useOrganizationConfig();

    useEffect(() => {
        methods.reset(dispute);
    }, [methods.reset, dispute]);

    const [openCancelDialog, cancelDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            handleCloseEditDisputeForm();
        }
    });

    const handleSaveChanges = async (values: DisputeEditFormValues) => {
        setSubmissionError(undefined);

        const response = await onSubmit(values);

        if (!response.success) setSubmissionError(response.error);
    };

    const handleDiscardEditDispute = () => {
        if (methods.formState.isDirty) {
            openCancelDialog();
        } else {
            handleCloseEditDisputeForm();
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(handleSaveChanges)}
                    noValidate
                >
                    <Box className="space-y-4">
                        <Box className="space-y-2">
                            <FormInputLabel required>Reason</FormInputLabel>
                            <Controller
                                name="reason"
                                control={methods.control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        value={value}
                                        onChange={onChange}
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ width: "100%" }}
                                    >
                                        {disputeeIsTalent ? (
                                            buildTalentDisputeReasonOptions(giggedClientTerminology).map(option =>
                                                <MenuItem key={option} value={option}>{option}</MenuItem>)
                                        ) : (
                                            buildClientDisputeReasonOptions(talentTerminology).map(option =>
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                )}
                            />
                        </Box>
                        <Box className="space-y-2">
                            <FormInputLabel required>Description</FormInputLabel>
                            <FormTextInput
                                required
                                name="description"
                                multiline
                            />
                        </Box>
                        <FormErrors messages={submissionError?.userMessages} />
                    </Box>
                    <Box className="flex flex-col-reverse md:flex-row md:justify-between md:items-center !mt-8">
                        <Button
                            color="secondary"
                            variant="text"
                            onClick={handleDiscardEditDispute}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            variant="contained"
                            startIcon={<SaveOutlinedIcon sx={!methods.formState.isDirty || loading ? { opacity: "26%" } : { opacity: "100%" }} />}
                            type="submit"
                            color="primary"
                            loading={loading}
                            disabled={!methods.formState.isDirty}
                            sx={!isLargeScreen ? { marginBottom: 2 } : { marginBottom: 0 }}
                        >
                            Save changes
                        </LoadingButton>
                    </Box>
                </form>
            </FormProvider>
            <ConfirmDialogBox
                {...cancelDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
        </>
    );
};

export default DisputeEditForm;