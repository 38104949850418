import { Box, Typography } from "@mui/material";
import ReportsTable from "../components/ReportsTable";
import useIsLargeScreen from "../hooks/useIsLargeScreen";

const ReportsListPage = () => {
    const isLargeScreen = useIsLargeScreen();

    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: ( isLargeScreen ?  3 : 2 )}}>
                <Typography component="h1" variant="h4" gutterBottom>Reports</Typography>
                <ReportsTable />
            </Box>
        </>
    );
};

export default ReportsListPage;