import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type TalentBadgesFormValues = {
    badgeId: string;
};

export const useTalentBadgesForm = (initialValues: TalentBadgesFormValues) => {
    const schema = talentBadgesValidationSchema();

    return useForm<TalentBadgesFormValues>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: initialValues,
    });
};

export const defaultTalentBadgesFormValues: TalentBadgesFormValues = {
    badgeId: "None",
};

const talentBadgesValidationSchema = () => {
    return yup.object({
        badgeId: yup.string().required("Please select a badge"),
    }).required();
};