import { Box, Typography, useTheme } from "@mui/material";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";

export type DashboardSectionProps = {
    children: React.ReactNode
    title: string
}

const DashboardSection = ({ children, title }: DashboardSectionProps) => {
    const isLargeScreen = useIsLargeScreen();
    const theme = useTheme();

    return (
        <Box sx={isLargeScreen ? {
            padding: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: "8px"
        } : {
            padding: 3,
            backgroundColor: theme.palette.background.paper
        }
        }>
            <Typography component="h4" variant="h4" gutterBottom>{title}</Typography>
            <div className="space-y-6 md:space-y-0 md:flex md:space-x-4 md:child:grow">
                {children}
            </div>
        </Box>
    );
};

export default DashboardSection;