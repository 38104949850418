const formatDateOnlyString = (parseableDateString?: string): string => {
    if (!parseableDateString) return "";

    return new Date(parseableDateString).toLocaleDateString(
        ["en-GB"], 
        {   year: "numeric", 
            month: "numeric", 
            day: "numeric"
        }
    );
};

export default formatDateOnlyString;
