import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "../common/giggedApiSWR";
import { ApiTalentStatisticsDto } from "../models/dashboardStatistics/talentStatistics";

export type UseTalentStatisticsReturn =
    UseGiggedApiSWRReturn<ApiTalentStatisticsDto> &
    {
        statistics?: ApiTalentStatisticsDto
        isLoading: boolean
    }

export const useTalentStatistics = (): UseTalentStatisticsReturn => {

    const url = "api/admin-dashboard/statistics/talents";

    const apiCall = useGiggedApiSWR<ApiTalentStatisticsDto>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        statistics: apiCall.data,
        isLoading: apiCall.isLoading
    };
};