import { GroupBase, MenuListProps } from "react-select";

const FormSearchDropdownMenuList = ({ 
    children,
    innerProps,
    innerRef 
}: MenuListProps<unknown, boolean, GroupBase<unknown>>) => (
    <div
        ref={innerRef}
        className="max-h-60 scrollbar-thin scrollbar-thumb-scrollbar scrollbar-track-white overflow-y-auto"
        {...innerProps}
    >
        {children}
    </div>
);

export default FormSearchDropdownMenuList;