import { useClients } from "../api/clients";
import FormContainer from "../components/FormContainer";
import { ClientCreateFormValues, ClientCreateForm } from "../components/ClientCreateForm";
import { useNavigate } from "react-router-dom";
import { useAppPaths } from "../Routes";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useOrganizationConfig } from "../api/organization";

const ClientCreatePage = () => {
    const appPaths = useAppPaths();
    const { createClient, isCreatingClient } = useClients();
    const navigate = useNavigate();
    const { giggedClientTerminology } = useOrganizationConfig();

    const onSubmit = async (values: ClientCreateFormValues): Promise<GigApiFetcherResponse<string>> => {
        const response = await createClient(values);

        if (response.success) {
            successToast(`${giggedClientTerminology} created.`);
            navigate(appPaths.clients.index);
        }

        return response;
    };

    return (
        <FormContainer>
            <ClientCreateForm onSubmit={onSubmit} isSubmitting={isCreatingClient} />
        </FormContainer>
    );
};

export default ClientCreatePage;
