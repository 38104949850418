import { dashboardOverviewTooltips } from "../../tooltipsContent";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashboardSection from "../../components/Dashboard/DashboardSection";
import DashboardSubSection from "../../components/Dashboard/DashboardSubSection";
import { ApiReportStatisticsDto } from "../../api/models/dashboardStatistics/reportStatistics";

export type DashboardReportSectionProps = {
    statistics?: ApiReportStatisticsDto
}

const DashboardReportsSection = ({
    statistics
}: DashboardReportSectionProps) => {
    return (
        <DashboardSection
            title="Reports"
        >
            <div className="flex flex-col space-y-2 w-full">
                <div className="flex flex-row space-x-2">
                    <DashboardCard
                        title="Total"
                        statistic={statistics?.createdTotal}
                        tooltip={dashboardOverviewTooltips.reportsTotal}
                    />
                    <DashboardCard
                        title="Resolved"
                        statistic={statistics?.reportsResolvedCount}
                        tooltip={dashboardOverviewTooltips.reportsResolved}
                    />
                </div>
                <DashboardSubSection
                    title="Created"
                    tooltip={dashboardOverviewTooltips.reportsCreated}
                >
                    <div className="flex flex-row space-x-2">
                        <DashboardCard
                            title="Last 7 days"
                            statistic={`${statistics?.createdLast7Days || 0} (+${statistics?.percentageIncreaseLast7Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 14 days"
                            statistic={`${statistics?.createdLast14Days || 0} (+${statistics?.percentageIncreaseLast14Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 30 days"
                            statistic={`${statistics?.createdLast30Days || 0} (+${statistics?.percentageIncreaseLast30Days || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 3 months"
                            statistic={`${statistics?.createdLast3Months || 0} (+${statistics?.percentageIncreaseLast3Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 6 months"
                            statistic={`${statistics?.createdLast6Months || 0} (+${statistics?.percentageIncreaseLast6Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 9 months"
                            statistic={`${statistics?.createdLast9Months || 0} (+${statistics?.percentageIncreaseLast9Months || 0}%)`}
                        />
                        <DashboardCard
                            title="Last 12 months"
                            statistic={`${statistics?.createdLast12Months || 0} (+${statistics?.percentageIncreaseLast12Months || 0}%)`}
                        />
                    </div>
                </DashboardSubSection>
                <DashboardSubSection
                    title="Reports by type"
                    tooltip={dashboardOverviewTooltips.reportsByType}
                >
                    {statistics?.reportTypeByCount && statistics?.reportTypeByCount.length ? (
                        <div className="flex flex-row space-x-2">
                            {statistics.reportTypeByCount.map(reportType => (
                                <DashboardCard
                                    title={reportType.reportName}
                                    statistic={reportType.reportCount}
                                />
                            ))}
                        </div>
                    ) : (
                        <DashboardCard
                            title="No data"
                        />
                    )}
                </DashboardSubSection>
            </div>
        </DashboardSection>
    );
};

export default DashboardReportsSection;