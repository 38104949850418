import gigApiFetcher from "./common/fetching";

export const validateClientEmail = async (email: string, token: string): Promise<unknown> => {
    const path = `api/organizations/validate-email?Email=${email}&Type=giggedclient`;
    const result = await gigApiFetcher({ path, token });

    return result;
};

export const getCurrentOrganizationId = async (): Promise<string> => {
    const path = `api/organizations/get-id-by-domain/${window.location.hostname}`;
    const organizationId = await gigApiFetcher<never, string>({
        path: path,
        method: "GET"
    });
    
    return organizationId;
};