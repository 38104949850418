import { useEffect, useState } from "react";
import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiAgencyDetailsDto, ApiUpdateAgencyDto } from "./models/api/agencies";
import { AgencyDetailsDto } from "./models/agencies";
import { mapFromApiAgencyDetailsDto } from "../mappers/agency";

export const useUpdateAgency = () => useAuthenticatedGigApiFetcher<ApiUpdateAgencyDto, void, { agencyId: string, dto: ApiUpdateAgencyDto }>("PUT",
    ({ agencyId, dto }) => ({
        path: `api/agencies/${agencyId}`,
        body: dto
    }));

export const useDeleteAgency = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (agencyId) => ({
        path: `api/agencies/${agencyId}`,
    }));

export type UseAgencyReturn<TAgencyId extends string | undefined> = TAgencyId extends undefined ?
    { agency: undefined } :
    {
        agency: AgencyDetailsDto
        updateAgency: (dto: ApiUpdateAgencyDto) => Promise<GigApiFetcherResponse<void>>
        deleteAgency: () => Promise<GigApiFetcherResponse<void>>
        isLoading: boolean
        isUpdatingAgency: boolean
        isDeletingAgency: boolean
    }

export const useAgency = <TAgencyId extends string | undefined>(agencyId: TAgencyId): UseAgencyReturn<TAgencyId> => {
    const url = `api/agencies/${agencyId}`;
    const apiCall = useGiggedApiSWR<ApiAgencyDetailsDto>(url);

    const [updateAgency, isUpdatingAgency] = useUpdateAgency();
    const [deleteAgency, isDeletingAgency] = useDeleteAgency();

    const [agency, setAgency] = useState<AgencyDetailsDto | undefined>();
    
    if (agencyId === undefined) return { agency: undefined } as UseAgencyReturn<TAgencyId>;

    useEffect(() => {
        if (apiCall.data === undefined) {
            setAgency(undefined);
            return;
        }

        setAgency(mapFromApiAgencyDetailsDto(apiCall.data));
    }, [apiCall.data]);

    return {
        ...apiCall,
        agency,
        updateAgency: async (dto: ApiUpdateAgencyDto) => {
            const response = await updateAgency({ agencyId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        deleteAgency: async () => {
            const response = await deleteAgency(agencyId);

            return response;
        },
        isLoading: apiCall.isLoading,
        isUpdatingAgency,
        isDeletingAgency
    } as UseAgencyReturn<TAgencyId>;
};