import clsx from "clsx";
import { FieldError } from "react-hook-form";

export type FormInputMessageProps = {
    error?: FieldError | string
    helpText?: string
    className?: string
}

const FormInputMessage = ({
    error,
    helpText,
    className,
}: FormInputMessageProps) => {
    const commonClassNames = clsx(
        "text-sm ml-4 block mt-2",
        className);

    if (error) {
        return (
            <span className={clsx(commonClassNames, "text-error text-[0.75rem]")}>{typeof error === "string" ? error : error?.message}</span>
        );
    }
    
    if (helpText) {
        return (
            <span className={clsx(commonClassNames, "text-primary-text")}>{helpText}</span>
        );
    }

    return null;
};

export default FormInputMessage;