import { Box, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";

export type NameDisplayLinkProps = {
    name: string
    href: string
}
const NameDisplayLink = ({
    name,
    href
}: NameDisplayLinkProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
            }}
        >

            <PersonIcon sx={{ marginRight: 1 }} />
            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                <Link to={href}>
                    <Typography component="span" fontSize="large" sx={{ textDecoration: "underline" }}>
                        {name}
                    </Typography>
                </Link>
            </Typography>
        </Box>
    );
};

export default NameDisplayLink;