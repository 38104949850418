import { useState } from "react";

const useDialogBoxState = () => {
    const [isOpen, setIsOpen] = useState(false);

    return {
        isOpen,
        setIsOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
    };
};

export default useDialogBoxState;