import CloseIcon from "@mui/icons-material/Close";
import { ClearIndicatorProps, GroupBase } from "react-select";

const FormSearchDropdownClearIndicator = ({
    innerProps
}: ClearIndicatorProps<unknown, boolean, GroupBase<unknown>>) => (
    <span 
        className="cursor-pointer inset-y-0 right-4"
        {...innerProps}
    >
        <CloseIcon
            className="h-5 w-5 text-primary-text"
            aria-hidden="true"
            
        />
    </span>
);

export default FormSearchDropdownClearIndicator;