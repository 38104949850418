import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";

export type CheckboxProps = {
    label: string,
    value: boolean,
    onChange: (value: boolean) => void,
}

const Checkbox = ({
    label,
    value,
    onChange,
}: CheckboxProps) => {
    return (
        <FormControlLabel
            sx={{
                width: "100%",
            }}
            control={
                <MuiCheckbox
                    checked={value}
                    onChange={(_, newValue) => onChange(newValue)} 
                />}
            label={label}
        />
    );
};

export default Checkbox;