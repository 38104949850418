import { AgencyDetailsDto, AgencySummaryDto } from "../api/models/agencies";
import { ApiAgencyDetailsDto, ApiAgencySummaryDto } from "../api/models/api/agencies";

export const mapFromApiAgencySummaryDto = (apiDto: ApiAgencySummaryDto): AgencySummaryDto => ({
    ...apiDto,
    id: apiDto.agencyId
});

export const mapFromApiAgencyDetailsDto = (apiDto: ApiAgencyDetailsDto): AgencyDetailsDto => ({
    ...apiDto,
    id: apiDto.agencyId
});