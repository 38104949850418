import { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useDebounce } from "use-debounce";

import SearchBox from "../SearchBox";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import DisputesTable from "./DisputesTable";
import { useOrganizationConfig } from "../../api/organization";

const DisputesTableWithSearch = () => {
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);
    const { gigTerminology } = useOrganizationConfig();

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                "> *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder={`Search by ${gigTerminology} title or Reason`}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={isLargeScreen ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />
            </Box>
            <Box sx={{ marginTop: "1.5rem" }}>
                <DisputesTable
                    searchTerm={debounceSearchTerm}
                />
            </Box>
        </>
    );
};

export default DisputesTableWithSearch;