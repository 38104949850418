import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ApiDisputeDetailsDto } from "../../api/models/api/disputes";
import { useAppPaths, useDisputeId } from "../../Routes";
import FormCard from "../FormCard";
import EditButton from "../EditButton";
import Loader from "../Loader";
import { DisputeEditFormValues, useEditDisputeForm } from "../DisputeEdit/DisputeEditFormValues";
import { useOrganizationConfig } from "../../api/organization";
import { useDispute } from "../../api/dispute";
import { successToast } from "../../toast";
import { DisputeEditForm } from "../DisputeEdit";
import ConfirmDialogBox from "../ConfirmDialogBox";
import useConfirmDialogBoxState from "../ConfirmDialogBox/useConfirmDialogBoxState";
import DisputeResolveForm from "./DisputeResolveForm";
import DialogBox from "../DialogBox/DialogBox";
import useDialogBoxState from "../DialogBox/useDialogBoxState";
import { DisputeResolveFormValues } from "./DisputeResolveFormValues";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { disputeStatusOptions } from "../../api/models/disputes";

export type DisputeViewPageProps = {
    dispute?: ApiDisputeDetailsDto
}

const DisputeViewPage = ({ dispute }: DisputeViewPageProps) => {
    const [isEditingDispute, setIsEditingDispute] = useState(false);
    const theme = useTheme();
    const appPaths = useAppPaths();
    const navigate = useNavigate();
    const { giggedClientTerminology, gigTerminology, talentTerminology } = useOrganizationConfig();
    const disputeId = useDisputeId();
    const { updateDispute, isUpdatingDispute, isLoading, resolveDispute, isResolvingDispute } = useDispute(disputeId);
    const methods = useEditDisputeForm(dispute);
    const dialogBoxState = useDialogBoxState();
    const isLargeScreen = useIsLargeScreen();
    const disputeStatuses = disputeStatusOptions(talentTerminology, giggedClientTerminology);

    const disputeIsEditable = dispute?.disputeStatus === 0;
    const disputeeIsTalent = dispute?.reason.includes(`${giggedClientTerminology}`);
    const disputeeName = disputeeIsTalent ? dispute?.talentName : dispute?.giggedClientName;

    const [openCloseDialog, closeDialogState] = useConfirmDialogBoxState({
        onConfirm: () => {
            navigate(appPaths.disputes.index);
        }
    });

    const handleCloseButton = () => {
        if (methods.formState.isDirty) {
            openCloseDialog();
        } else {
            navigate(appPaths.disputes.index);
        }
    };

    const handleCloseEditDisputeForm = () => {
        setIsEditingDispute(false);
    };

    const handleEditButtonClick = () => {
        setIsEditingDispute(!isEditingDispute);
    };

    const handleSubmit = async (values: DisputeEditFormValues) => {
        const response = await updateDispute(values);

        if (response.success) {
            setIsEditingDispute(false);
            successToast("Dispute successfully updated.");
        }

        return response;
    };

    const handleResolveDispute = async (values: DisputeResolveFormValues) => {
        if (values.isTalentWinner === null) throw Error("Expected dispute winner not to be null.");

        const response = await resolveDispute({
            ...values,
            isTalentWinner: JSON.parse(values.isTalentWinner)
        });

        if (response.success) {
            successToast("Dispute successfully resolved.");
            handleCloseResolveFormDialog();
        }

        return response;
    };

    const handleCloseResolveFormDialog = () => {
        dialogBoxState.close();
    };

    if (!dispute) return <Loader />;

    return (
        <>
            <FormCard
                title="Dispute"
                onClose={handleCloseButton}
            >
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        ID: <Typography sx={{ marginLeft: "8px" }} component="span">{dispute.disputeId}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {giggedClientTerminology}:
                        <Link to={appPaths.clients.edit(dispute.giggedClientId)}>
                            <Typography sx={{ marginLeft: "12px", textDecoration: "underline" }} component="span">{dispute.giggedClientName}</Typography>
                        </Link>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {talentTerminology}: <Typography sx={{ marginLeft: "8px" }} component="span">{dispute.talentName}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Created by: <Typography sx={{ marginLeft: "8px" }} component="span">{disputeeName}</Typography>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        {gigTerminology}:
                        <Link to={appPaths.gigs.view(dispute.gigId)}>
                            <Typography sx={{ marginLeft: "12px", textDecoration: "underline" }} component="span">{dispute.gigTitle}</Typography>
                        </Link>
                    </Typography>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Status: <Typography sx={{ marginLeft: "8px" }} component="span">{disputeStatuses[dispute.disputeStatus].label}</Typography>
                    </Typography>
                    {!isEditingDispute ? (
                        <>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Reason: <Typography sx={{ marginLeft: "8px" }} component="span">{dispute.reason}</Typography>
                            </Typography>
                            <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                                Description: <Typography sx={{ marginLeft: "8px" }} component="span">{dispute.description}</Typography>
                            </Typography>
                            <Box sx={ !isLargeScreen ? { display: "flex", flexDirection: "column-reverse", marginTop: "2rem" } : { display: "flex", flexDirection: "flex-row", justifyContent: "space-between", marginTop: 2 }}>
                                <EditButton
                                    disabled={!disputeIsEditable}
                                    buttonText="Edit dispute"
                                    disabledTooltip="This dispute cannot be edited as it is already marked as resolved."
                                    onClick={handleEditButtonClick}
                                />
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    disabled={dispute.disputeStatus !== 0}
                                    onClick={dialogBoxState.open}
                                >
                                    {dispute.disputeStatus === 0 ? "Resolve now" : "Resolved"}
                                </Button>
                            </Box>
                        </>
                    ) : (
                        <DisputeEditForm
                            dispute={dispute}
                            onSubmit={handleSubmit}
                            handleCloseEditDisputeForm={handleCloseEditDisputeForm}
                            disputeeIsTalent={disputeeIsTalent}
                            loading={isUpdatingDispute || isLoading}
                            methods={methods}
                        />
                    )}
                </Box>
            </FormCard>
            <ConfirmDialogBox
                {...closeDialogState}
                message="Discard unsaved changes?"
                confirmButtonText="Discard"
            />
            <DialogBox
                {...dialogBoxState}
                fullWidth
                maxWidth="sm"
                title="Dispute resolution"
                contentText="Please resolve the dispute by selecting the winning party below. An email will be sent to both parties with your message."
            >
                <DisputeResolveForm
                    handleCloseDialog={handleCloseResolveFormDialog}
                    onSubmit={handleResolveDispute}
                    loading={isResolvingDispute || isLoading}
                />
            </DialogBox>
        </>
    );
};

export default DisputeViewPage;