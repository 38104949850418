import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export type DisputeEditFormValues = {
    reason: string
    description: string
}

const DisputeEditValidationSchema = yup
    .object({
        reason: yup.string().required("Reason is required."),
        description: yup.string().required("Description is required.")
    }).required();

export const useEditDisputeForm = (initialValues?: DisputeEditFormValues) => {
    return useForm<DisputeEditFormValues>({
        resolver: yupResolver(DisputeEditValidationSchema),
        mode: "onSubmit",
        defaultValues: {
            reason: initialValues?.reason || "",
            description: initialValues?.description || ""
        }
    });
};