import { createTheme, ThemeOptions } from "@mui/material";
import type { } from "@mui/lab/themeAugmentation";

const palette = {
    primary: {
        main: "#36DD93", // green
        light: "#C0FFD9",
        dark: "#002110",
        contrastText: "#00C47D"
    },
    secondary: {
        main: "#0B1B29", // navy
        light: "#D8E2EC"
    },
    tertiary: {
        main: "#FFFFFF", // white
        dark: "#0B1B2999",
        light: "#E0E8F0"
    },
    error: {
        main: "#BA1B1B", // red
        light: "#FFDAD4",
        dark: "#410001",
    },
    background: {
        default: "#FCFCFF",
        paper: "#EFF3F8",
    },
};

const theme: ThemeOptions = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "medium",
                    backgroundColor: "white",
                    color: palette.secondary.main,
                    padding: "1rem",
                    borderRadius: 8,
                    boxShadow: "rgba(0, 0, 0, 0.20) 0px 5px 10px",
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    color: palette.secondary.main,
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                    flexShrink: 0,
                    fontSize: 16,
                    textTransform: "unset",
                    borderRadius: 24,
                    paddingTop: 7,
                    paddingRight: 24,
                    paddingBottom: 7,
                    paddingLeft: 24,
                    borderColor: "black"
                },
                containedPrimary: {
                    backgroundColor: palette.primary.main,
                    transition: "none",
                    "&:active, &:hover": {
                        backgroundColor: palette.primary.contrastText,
                    },
                    "&:focus": {
                        backgroundColor: palette.primary.main,
                        boxShadow: "0px 0px 0px 3px black inset",
                        transition: "none"
                    },
                    "&:focus&:hover": {
                        backgroundColor: palette.primary.contrastText,
                    },
                    "&:active": {
                        backgroundColor: palette.primary.contrastText,
                        boxShadow: "none !important",
                    },
                },
                outlinedSecondary: {
                    boxShadow: "0px 0px 0px 1px black",
                    transition: "none",
                    "&:active, &:hover": {
                        backgroundColor: palette.tertiary.light,
                        boxShadow: "0px 0px 0px 1px black",
                    },
                    "&:focus": {
                        backgroundColor: palette.background.paper,
                        boxShadow: "0px 0px 0px 2px black",
                    },
                    "&:focus&:hover": {
                        backgroundColor: palette.tertiary.light
                    },
                },
                textSecondary: {
                    "&:focus": {
                        boxShadow: "0px 0px 0px 2px black",
                    },
                    "&:active": {
                        boxShadow: "none",
                        border: "none"
                    },
                    "&:hover": {
                        backgroundColor: palette.tertiary.light
                    }
                },
                text: {
                    "&:hover": {
                        backgroundColor: palette.tertiary.light
                    },
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.secondary.main,
                    borderBottom: `3px solid ${palette.primary.main}`,
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.tertiary.main,
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    color: `${palette.tertiary.main} !important`,
                    fontWeight: 700,
                },
                root: {
                    color: `${palette.tertiary.main} !important`,
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: `solid ${palette.secondary.light} 1.5px !important`
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: `${palette.tertiary.main} !important`,
                    fontWeight: 700,
                },
                body: {
                    color: palette.secondary.main
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px"
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                menuItem: {
                    "&:hover": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                    "&.Mui-selected": {
                        backgroundColor: `${palette.primary.light} !important`,
                    },
                },
                input: {
                    border: "1px solid black",
                    borderRadius: "4px"
                },
            }
        },
        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    backgroundColor: palette.tertiary.main,
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                    color: palette.secondary.main
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: palette.primary.light
                    }
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main
                },
                fontSizeSmall: {
                    color: palette.tertiary.main
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: `${palette.secondary.main} !important`
                    }
                },
                asterisk: {
                    color: palette.error.main
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    "& label": {
                        color: palette.tertiary.dark
                    },
                    "& label.Mui-focused": {
                        color: palette.secondary.main,
                    },
                    "& label.Mui-error": {
                        color: palette.error.main,
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: palette.secondary.main,
                            borderRadius: "12px",
                        },
                        "&:hover fieldset": {
                            borderColor: palette.secondary.main,
                        },
                        "&.Mui-focused fieldset": {
                            borderWidth: "3px !important",
                            borderColor: palette.secondary.main
                        },
                        "& input:valid + fieldset": {
                            borderColor: palette.secondary.main,
                            borderWidth: 1,
                        },
                        "& label.input:valid + fieldset": {
                            color: palette.secondary.main,
                            backgroundColor: "red"
                        },
                        "&.Mui-error fieldset": {
                            borderColor: palette.error.main,
                            borderWidth: "3px",
                        },
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                filled: {
                    fontWeight: 700,
                    color: palette.secondary.main,
                    "& .MuiChip-deleteIcon": {
                        color: palette.tertiary.main,
                        "&:hover": {
                            color: `${palette.tertiary.main} !important`
                        }
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    fontSize: "28px"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "white",
                    borderRadius: "12px",
                },
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    minWidth: "100% !important"
                },
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "8px"
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    boxShadow: "black"
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    border: "1px solid black",
                    borderRadius: "12px"
                },
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        opacity: "25%"
                    }
                }
            }
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    "& *.Mui-selected": {
                        "&:focus":{
                            backgroundColor: `${palette.primary.main} !important` 
                        },
                        "&:hover": {
                            backgroundColor: `${palette.primary.main} !important` 
                        }
                    }
                }
            }
        }    
    },
    palette: {
        primary: {
            main: palette.primary.main, // green
            light: palette.primary.light,
            dark: palette.primary.dark,
        },
        secondary: {
            main: palette.secondary.main, // navy
            light: palette.secondary.light,
        },
        tertiary: {
            main: palette.tertiary.main, // white
            dark: palette.tertiary.dark,
        },
        error: {
            main: palette.error.main,
            light: palette.error.light,
            dark: palette.error.dark,
        },
        background: {
            default: palette.background.default,
            paper: palette.background.paper,
        },
    },
    typography: {
        fontFamily: "Mulish, sans-serif",
        h1: {
            fontSize: 57,
        },
        h2: {
            fontSize: 45,
        },
        h3: {
            fontSize: 36,
        },
        h4: {
            fontSize: 32,
        },
        fontWeightBold: 700,
    }
});

export default theme;