import { LoadingButton } from "@mui/lab";
import { Button, SxProps, Theme } from "@mui/material";
import DialogBox from "../DialogBox/DialogBox";

export type ConfirmDialogProps = {
    message: string
    warningMessage?: string
    confirmButtonText?: string
    cancelButtonText?: string
    sx?: SxProps<Theme>
    isOpen: boolean
    isProcessingConfirm?: boolean
    onConfirm: () => void
    onCancel: () => void
}

const ConfirmDialogBox = ({
    message,
    warningMessage,
    isOpen,
    isProcessingConfirm,
    confirmButtonText = "Ok",
    cancelButtonText = "Cancel",
    sx,
    onConfirm,
    onCancel,
}: ConfirmDialogProps) => {
    return (
        <DialogBox
            isOpen={isOpen}
            contentText={message}
            warningText={warningMessage}
            sx={sx}
            buttons={
                <>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onCancel}
                        disabled={isProcessingConfirm}
                        sx={{ marginRight: "auto", minWidth: "7rem" }}
                    >
                        {cancelButtonText}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={isProcessingConfirm}
                        onClick={onConfirm}
                        sx={{ minWidth: "7rem"}}
                    >
                        {confirmButtonText}
                    </LoadingButton>
                </>
            }
        />
    );
};

export default ConfirmDialogBox;
