import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Button } from "@mui/material";
import { useAuthContext } from "../auth/authContext";

export const SignInButton = () => {
    const { instance, inProgress } = useMsal();
    const { loginRequest } = useAuthContext();
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    
    const isLoading = isLoggingIn || inProgress === InteractionStatus.HandleRedirect;

    const handleLogin = async () => {
        setIsLoggingIn(true);
        try {
            await instance.loginRedirect(loginRequest);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoggingIn(false);
        }
    };

    return (
        <Button 
            onClick={handleLogin} 
            variant="text"
            color="secondary" 
            size="medium"
        > 
            {isLoading ? "Loading..." : "Sign in" } 
        </Button>
    );
};