import { Typography } from "@mui/material";
import { useOrganizationConfig } from "../../api/organization";

export type AssignedTalentDisplayProps = {
    talentName: string
}

const AssignedTalentDisplay = ({
    talentName
}: AssignedTalentDisplayProps) => {
    const { talentTerminology } = useOrganizationConfig();

    return (
        <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
            Assigned {talentTerminology}: <Typography component="span">{talentName}</Typography>
        </Typography>
    );
};

export default AssignedTalentDisplay;