const capitalizeString = (string: string) => {
    return string[0].toUpperCase() + string.slice(1);
};

export const capitalizeStrings = (string: string) => {
    return string
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

export default capitalizeString;