import { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiDisputeDetailsDto, ApiResolveDisputeDto, ApiUpdateDisputeDto } from "./models/api/disputes";

export const useUpdateDispute = () => useAuthenticatedGigApiFetcher<ApiUpdateDisputeDto, void, { disputeId: string, dto: ApiUpdateDisputeDto }>("PUT",
    ({ disputeId, dto }) => ({
        path: `api/disputes/${disputeId}`,
        body: dto
    }));

export const useResolveDispute = () => useAuthenticatedGigApiFetcher<ApiResolveDisputeDto, void, { disputeId: string, dto: ApiResolveDisputeDto }>("POST",
    ({ disputeId, dto }) => ({
        path: `api/disputes/${disputeId}/actions/resolve`,
        body: dto
    }));

export type UseDisputeReturn<TDisputeId extends string | undefined> = TDisputeId extends undefined ?
    { dispute: undefined } :
    {
        dispute: ApiDisputeDetailsDto
        updateDispute: (dto: ApiUpdateDisputeDto) => Promise<GigApiFetcherResponse<void>>
        resolveDispute: (dto: ApiResolveDisputeDto) => Promise<GigApiFetcherResponse<void>>
        isLoading: boolean
        isUpdatingDispute: boolean
        isResolvingDispute: boolean
    }

export const useDispute = <TDisputeId extends string | undefined>(disputeId: TDisputeId) => {
    const [updateDispute, isUpdatingDispute] = useUpdateDispute();
    const [resolveDispute, isResolvingDispute] = useResolveDispute();
    const url = `api/disputes/${disputeId}`;

    const apiCall = useGiggedApiSWR<ApiDisputeDetailsDto>(url);

    if (disputeId === undefined) return { dispute: undefined } as UseDisputeReturn<TDisputeId>;

    return {
        ...apiCall,
        dispute: apiCall.data,
        updateDispute: async (dto: ApiUpdateDisputeDto) => {
            const response = await updateDispute({ disputeId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        resolveDispute: async (dto: ApiResolveDisputeDto) => {
            const response = await resolveDispute({ disputeId, dto });

            if (response.success) apiCall.mutate();

            return response;
        },
        isLoading: apiCall.isLoading,
        isUpdatingDispute,
        isResolvingDispute
    } as UseDisputeReturn<TDisputeId>;
};