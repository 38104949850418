import { Configuration, LogLevel } from "@azure/msal-browser";
import config from "../config.json";

const clientId = config.AdminDashboardClientId;
const b2cTenantName = config.AuthB2cTenantName;
const b2cSignUpSignInPolicyName = config.AuthB2cSignUpSignInPolicyName;

export const b2cPolicies = {
    names: {
        signUpSignIn: b2cSignUpSignInPolicyName,
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${b2cTenantName}.b2clogin.com/${b2cTenantName}.onmicrosoft.com/${b2cSignUpSignInPolicyName}`,
        },
    },
    authorityDomain: `${b2cTenantName}.b2clogin.com`,
};

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, // Set this to "true" if there are having issues on IE11 or Edge
    },
    system: {	
        allowNativeBroker: false,
        loggerOptions: {	
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                case LogLevel.Error:		
                    console.error(message);		
                    return;		
                case LogLevel.Info:		
                    console.info(message);		
                    return;		
                case LogLevel.Verbose:		
                    console.debug(message);	
                    return;		
                case LogLevel.Warning:		
                    console.warn(message);		
                    return;		
                }	
            }	
        }	
    }
};