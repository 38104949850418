import { Paper, Typography, useTheme } from "@mui/material";
import HelpIcon from "../HelpIcon";

export type DashboardCardProps = {
    title: string
    statistic?: React.ReactNode
    tooltip?: string
}

const DashboardCard = ({
    title, 
    statistic ,
    tooltip
}: DashboardCardProps) => {
    const theme = useTheme();

    return (
        <Paper
            elevation={0}
            sx={{ backgroundColor: theme.palette.background.default }}
            className="flex flex-row h-auto p-4 justify-between rounded-lg w-full md:max-w-[50%] min-w-[150px]"
        >
            <div className="flex flex-col justify-between space-y-2">
                <Typography
                    variant="body2"
                    component="label"
                    className="text-secondary-bg-text !font-bold"
                >
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    component="ol"
                    className="text-secondary-bg-text list-none"
                >
                    {statistic}
                </Typography>
            </div>
            {tooltip && <HelpIcon title={tooltip} />}
        </Paper>
    );
};

export default DashboardCard;