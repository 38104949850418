import { useEffect, useState } from "react";
import { useAuthenticatedGigApiFetcher } from "./common/fetching";
import { useGiggedApiSWR } from "./common/giggedApiSWR";
import { ApiUpdateBadgeDto } from "./models/api/badge";
import { BadgeSummaryDto } from "./models/badge";

export const useUpdateBadge = () => useAuthenticatedGigApiFetcher<ApiUpdateBadgeDto, void, { badgeId: string, dto: ApiUpdateBadgeDto }>("PUT",
    ({ badgeId, dto }) => ({
        path: `api/badges/${badgeId}`,
        body: dto
    }));

export const useDeleteBadge = () => useAuthenticatedGigApiFetcher<never, void, string>("DELETE",
    (badgeId) => ({
        path: `api/badges/${badgeId}`,
    }));

export const useBadge = (badgeId: string) => {
    const url = `api/badges/${badgeId}`;
    const apiCall = useGiggedApiSWR<BadgeSummaryDto>(url);

    const [updateBadge, isUpdatingBadge] = useUpdateBadge();
    const [deleteBadge, isDeletingBadge] = useDeleteBadge();

    const [badge, setBadge] = useState<BadgeSummaryDto | undefined>();

    useEffect(() => {
        if (apiCall.data === undefined) {
            setBadge(undefined);
            return;
        }

        setBadge(apiCall.data);
    }, [apiCall.data]);

    return {
        ...apiCall,
        badge,
        updateBadge: async (dto: ApiUpdateBadgeDto) => {
            const response = await updateBadge({
                badgeId,
                dto
            });

            if (response.success) apiCall.mutate();

            return response;
        },
        deleteBadge: async () => {
            const response = await deleteBadge(badgeId);

            return response;
        },
        isUpdatingBadge,
        isDeletingBadge,
    };
};