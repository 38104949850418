import { Box, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import TitleUnderline from "./TitleUnderline";
import useIsLargeScreen from "../hooks/useIsLargeScreen";

export type FormCardProps = {
    title: React.ReactNode
    onClose?: () => void
    children: React.ReactNode
    withoutTitleUnderline?: boolean
    width?: string
    editTitleButton?: React.ReactNode
}

const FormCard = ({
    title,
    onClose,
    withoutTitleUnderline = false,
    width = "fit-content",
    children,
    editTitleButton
}: FormCardProps) => {
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();

    return (
        <Box 
            className="break-words"
            sx={isLargeScreen ? {
                padding: 4,
                backgroundColor: theme.palette.background.paper,
                borderRadius: "8px"
            } : {
                padding: 3,
                backgroundColor: theme.palette.background.paper
            }
            }
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: theme.spacing(2) }}>
                <Box sx={{ width: { width }, maxWidth: "90%", marginBottom: "1.5rem" }}>
                    <div className="flex flex-col">
                        {!editTitleButton ? (
                            <Typography variant="h4" component="h1" gutterBottom>
                                {title}
                            </Typography>
                        ) : (
                            <div className="flex flex-row">
                                <Typography variant="h4" component="h1" gutterBottom>
                                    {title}
                                </Typography>
                                {editTitleButton}
                            </div>

                        )}
                        {!withoutTitleUnderline &&
                            <TitleUnderline />
                        }
                    </div>
                </Box>
                {onClose && !editTitleButton && <CloseIcon sx={{ marginLeft: "1rem", cursor: "pointer", color: theme.palette.secondary.main }} fontSize="large" onClick={onClose} />}
                {onClose && editTitleButton && !withoutTitleUnderline && <CloseIcon sx={{ marginLeft: "1rem", cursor: "pointer", color: theme.palette.secondary.main }} fontSize="large" onClick={onClose} />}
            </Box>
            {children}
        </Box>
    );
};

export default FormCard;

