import { Box, Button, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { ApiReportDetailsDto } from "../../api/models/api/reports";
import { useAppPaths, useReportId } from "../../Routes";
import FormCard from "../FormCard";
import Loader from "../Loader";
import { useOrganizationConfig } from "../../api/organization";
import formatDateTimeString from "../../utils/formatDateTimeString";
import DialogBox from "../DialogBox/DialogBox";
import ReportResolveForm from "../ReportView/ReportResolveForm";
import useDialogBoxState from "../DialogBox/useDialogBoxState";
import { successToast } from "../../toast";
import { ReportResolveFormValues } from "../ReportView/ReportResolveFormValues";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";
import { useReport } from "../../api/report";

export type ReportViewPageProps = {
    report?: ApiReportDetailsDto
}

const ReportViewPage = ({ report }: ReportViewPageProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const appPaths = useAppPaths();
    const { 
        giggedClientTerminology, 
        gigTerminology, 
        talentTerminology, 
        gigDashboardDomain 
    } = useOrganizationConfig();

    const dialogBoxState = useDialogBoxState();
    const isLargeScreen = useIsLargeScreen();

    const reportId = useReportId();
    const { resolveReport, isResolvingReport } = useReport(reportId);

    if (!report) return <Loader />;

    const handleResolveReport = async (values: ReportResolveFormValues) => {
        const response = await resolveReport(reportId, values);

        if (response.success) {
            successToast("Report successfully resolved.");
            handleCloseResolveFormDialog();
        }

        return response;
    };

    const handleCloseResolveFormDialog = () => {
        dialogBoxState.close();
    };

    const handleCloseButton = () => {        
        navigate(appPaths.reports.index);        
    };

    const getTypeLabel = (typeName: string): string => {
        if (typeName === "GiggedClient") return giggedClientTerminology;
        if (typeName === "Talent") return talentTerminology;
        return gigTerminology;
    };

    const getEntityLink = (typeName: string, reportedEntityId: string): string => {
        if (typeName === "GiggedClient") return appPaths.clients.edit(reportedEntityId);
        if (typeName === "Talent") return `https://${gigDashboardDomain}/find-talent/${reportedEntityId}`;
        return appPaths.gigs.view(reportedEntityId);
    };

    const typeLabel = getTypeLabel(report.typeName);
    const entityLink = getEntityLink(report.typeName, report.reportedEntityId);

    return (
        <>
            <FormCard
                title="Report"
                onClose={handleCloseButton}
            >
                <Box sx={{
                    "> *:not(:last-child)": {
                        marginBottom: theme.spacing(3),
                    }
                }}>
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        ID: <Typography sx={{ marginLeft: "8px" }} component="span">{report.id}</Typography>
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Type:                    
                        <Typography sx={{ marginLeft: "8px" }} component="span">
                            {typeLabel}
                        </Typography>                    
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Reported {typeLabel.toLowerCase()}: 
                        <Link to={entityLink}>
                            <Typography sx={{ marginLeft: "12px", textDecoration: "underline" }} component="span">
                                {report.reportedEntityName}
                            </Typography>
                        </Link>
                    </Typography>
                    
                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Status: 
                        <Typography sx={{ marginLeft: "8px" }} component="span">
                            {report.isResolved ? "Resolved" : "Pending"}
                        </Typography>
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Reason: 
                        <Typography sx={{ marginLeft: "8px" }} component="span">
                            {report.reason}
                        </Typography>
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Description: 
                        <Typography sx={{ marginLeft: "8px" }} component="span">
                            {report.description}
                        </Typography>
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Created at: 
                        <Typography sx={{ marginLeft: "8px" }} component="span">
                            {formatDateTimeString(report.createdAt)}
                        </Typography>
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Created by: 
                        <Link to={appPaths.users.edit(report.createdByUserId)}>
                            <Typography sx={{ marginLeft: "12px", textDecoration: "underline" }} component="span">
                                {report.createdByUserName}
                            </Typography>
                        </Link>
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Resolution date: 
                        <Typography sx={{ marginLeft: "8px" }} component="span">
                            {report.resolutionDate ? formatDateTimeString(report.resolutionDate) : "N/A"}
                        </Typography>
                    </Typography>

                    <Typography fontWeight="bold" component="label" sx={{ display: "block" }}>
                        Resolution message: 
                        <Typography sx={{ marginLeft: "8px" }} component="span">
                            {report.resolutionMessage || "N/A"}
                        </Typography>
                    </Typography>
                    <Box sx={ !isLargeScreen ? { display: "flex", flexDirection: "column-reverse", marginTop: "2rem" } : { display: "flex", flexDirection: "flex-row", justifyContent: "space-between", marginTop: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={report.isResolved}
                            onClick={dialogBoxState.open}
                        >
                            {report.isResolved ? "Resolved" : "Resolve now"}
                        </Button>
                    </Box>
                </Box>
            </FormCard>
            <DialogBox
                {...dialogBoxState}
                fullWidth
                maxWidth="sm"
                title="Report resolution"
                contentText="Please enter the resolution message below. An email will be sent to the user who created the report."
            >
                <ReportResolveForm
                    handleCloseDialog={handleCloseResolveFormDialog}
                    onSubmit={handleResolveReport}
                    loading={isResolvingReport}
                />
            </DialogBox>
        </>
    );
};

export default ReportViewPage;