import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Fab, useMediaQuery, useTheme, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDebounce } from "use-debounce";

import { useAppPaths } from "../../Routes";
import SearchBox from "../SearchBox";
import BadgesTable from "./BadgesTable";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";

const BadgesTableWithSearch = () => {
    const appPaths = useAppPaths();
    const theme = useTheme();
    const isLargeScreen = useIsLargeScreen();
    const showFilterChipsInline = useMediaQuery("(min-width: 800px)");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debounceSearchTerm] = useDebounce(searchTerm, 700);

    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: theme.spacing(3),
                justifyContent: "space-between",
                "> *:not(:first-of-type)": {
                    marginLeft: 1,
                },
            }}>
                <SearchBox
                    placeholder={isLargeScreen ? "Search by badge name" : "Search Badges"}
                    onChange={setSearchTerm}
                    searchTerm={searchTerm}
                    sx={showFilterChipsInline ? { minWidth: "28rem", background: "#FFFFFF" } : { flexGrow: 1, background: "#FFFFFF" }}
                />
                {isLargeScreen && (
                    <Link to={appPaths.badges.create} style={{ textDecoration: "none", outline: "none", marginLeft: "1.25rem" }}>
                        <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                        >
                            Create
                        </Button>
                    </Link>
                )}
            </Box>
            <Box sx={{ marginTop: "1.5rem" }}>
                <BadgesTable
                    searchTerm={debounceSearchTerm}
                />
            </Box>
            {!isLargeScreen && (
                <Link to={appPaths.badges.create}>
                    <Fab
                        color="primary"
                        aria-label="create-badge"
                        sx={{
                            position: "fixed",
                            bottom: theme.spacing(10),
                            right: theme.spacing(4),
                        }}
                    >
                        <AddIcon fontSize="large" />
                    </Fab>
                </Link>
            )}
        </>
    );
};

export default BadgesTableWithSearch;