import { Box, Typography } from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";

export type StatusDisplayProps = {
    status: string
}

const StatusDisplay = ({
    status
}: StatusDisplayProps) => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
            }}
        >
            <PendingIcon sx={{ marginRight: 1 }} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography component="span">
                    {status}
                </Typography>
            </Box>
        </Box>
    );
};

export default StatusDisplay;