import { AuthenticatedTemplate } from "@azure/msal-react";
import { Disclosure } from "@headlessui/react";
import React from "react";
import { useIsExtraLargeScreen } from "../../hooks/screenSizes";
import useCurrentUser from "../../hooks/useCurrentUser";
import SideNavMenuItem from "./SideNavMenuItem";
import { buildClientNavItems } from "./SideNavMenuItems";
import { SignOutButton } from "./SignOutButton";

export type SideNavMenuProps = {
    close: () => void
}

export const sideNavWidth = "288px";

const SideNavMenu = ({
    close,
}: SideNavMenuProps) => {
    const isExtraLarge = useIsExtraLargeScreen();
    const currentUser = useCurrentUser();

    const wrapInContainer = (children: React.ReactNode) => (
        isExtraLarge ? (
            <div className={`fixed xl:static inset-y-0 left-0 w-[${sideNavWidth}] shrink-0 p-5 pr-12 bg-white z-40`}>
                {children}
            </div>
        ) : (
            <Disclosure.Panel className={`fixed inset-y-0 left-0 w-[${sideNavWidth}] p-5 pr-12 bg-white z-40 overflow-y-auto`}>
                {children}
            </Disclosure.Panel>
        )
    );

    return wrapInContainer(
        <nav>
            <div className="h-32 w-32 pt-[4px] text-[54px] flex items-center justify-center rounded-full bg-surface mx-auto mb-8">
                {currentUser.userGivenName?.length > 0 && currentUser.userGivenName[0].toUpperCase()}
            </div>

            <p className="text-center text-title-medium font-bold mb-8">
                {currentUser.userGivenName}
            </p>
            <div className="ml-4">
                {buildClientNavItems().map((navItem) => (
                    <SideNavMenuItem
                        key={navItem.name}
                        item={navItem}
                        onLinkClick={() => !isExtraLarge && close()}
                    />
                ))}
                <AuthenticatedTemplate>
                    <SignOutButton className="!bg-white !m-0 !p-2 !rounded-sm"/>
                </AuthenticatedTemplate>
            </div>
        </nav>
    );
};

export default SideNavMenu;
