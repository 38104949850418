import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { GigApiFetcherResponse } from "../api/common/fetching";
import { successToast } from "../toast";
import { useSkillCategoryId } from "../Routes";
import { useSkillCategory } from "../api/skillCategory";
import { SkillCategoryEditFormValues } from "../components/SkillCategoryEditForm/SkillCategoryEditFormValues";
import SkillCategoryEditForm from "../components/SkillCategoryEditForm/SkillCategoryEditForm";

const SkillCategoriesEditPage = () => {
    const skillCategoryId = useSkillCategoryId();
    const skillCategory = useSkillCategory(skillCategoryId as string);

    const onSubmit = async (values: SkillCategoryEditFormValues): Promise<GigApiFetcherResponse<void>> => {
        const response = await skillCategory.updateSkillCategory(values);

        if (response.success) {
            successToast("Category successfully updated.");
        }

        return response;
    };

    const handleDelete = async () => {
        const response = await skillCategory.deleteSkillCategory();

        if (response.success) {
            successToast("Category successfully deleted.");
        }

        return response;
    };

    if (skillCategory.isLoading || !skillCategory.skillCategory) return <Loader />;

    return (
        <FormContainer>
            <SkillCategoryEditForm
                initialValues={skillCategory.skillCategory}
                onSubmit={onSubmit}
                onDelete={handleDelete}
                isSubmitting={skillCategory.isUpdatingSkillCategory}
                isDeleting={skillCategory.isDeletingSkillCategory}
            />
        </FormContainer>
    );
};

export default SkillCategoriesEditPage;
