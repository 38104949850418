import { AuthenticatedTemplate } from "@azure/msal-react";
import { Disclosure } from "@headlessui/react";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { useOrganizationConfig } from "../../api/organization";
import UserMenu from "./UserMenu";


const TopNavMenu = () => {
    const { organizationConfig } = useOrganizationConfig();
    
    const organizationLogo = () => {
        if (organizationConfig?.logoUrl) {
            return (
                <img
                    className="block md:max-w-[120px] max-h-[30px] md:max-h-[40px] mr-auto"
                    src={organizationConfig.logoUrl}
                    alt="Organisation logo"
                />
            );
        }
        return (
            <img
                className="block max-h-[25px] w-auto mr-auto"
                src="/gigged-logo.svg"
                alt="Gigged.ai logo"
            />
        );
    };

    return (
        <nav className="px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center" >
                <Disclosure.Button className={clsx("flex",
                    "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black mr-4",
                    "xl:hidden"
                )}>
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                </Disclosure.Button>
                {organizationLogo()}
                <AuthenticatedTemplate>
                    <UserMenu />
                </AuthenticatedTemplate>
            </div>
        </nav>
    );
};

export default TopNavMenu;