export type EditableBadgeFields = {
    name: string
    description: string
    imageFileName: string
    typeId: number
}

export type CreateBadgeDto = EditableBadgeFields;

export type BadgeSummaryDto = EditableBadgeFields & {
    badgeId: string
    talentCount: number
    giggedClientCount: number
    createdAt: string
    type: string
}

export type UpdateBadgeDto = EditableBadgeFields;

export const badgeTypeOptions = (talentTerminology: string, giggedClientTerminology: string) => {
    return [
        { value: 0, label: talentTerminology },
        { value: 1, label: giggedClientTerminology }
    ];
};

export const badgeTypes = {
    talent: 0,
    client: 1
};