import { Typography, Box } from "@mui/material";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import BadgesTable from "../components/BadgesTable";

const BadgesListPage = () => {
    const isLargeScreen = useIsLargeScreen();

    return (
        <>
            <Box sx={{ marginTop: "2rem", margin: (isLargeScreen ? 3 : 2) }}>
                <Typography variant="h4" gutterBottom>Badges</Typography>
                <BadgesTable />
            </Box>
        </>
    );
};

export default BadgesListPage;