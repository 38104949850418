import React, { useState } from "react";
import { GigApiPaginatedData } from "./common/apiResponse";
import { useGiggedApiSWR, UseGiggedApiSWRReturn } from "./common/giggedApiSWR";
import { SortOrder } from "./common/sortOrder";
import { SkillCategorySummary, CreateSkillCategoryDto } from "./models/skillCategory";
import gigApiFetcher, { GigApiFetcherResponse, useAuthenticatedGigApiFetcher } from "./common/fetching";

export const useCreateSkillCategory = () => useAuthenticatedGigApiFetcher<CreateSkillCategoryDto, string, CreateSkillCategoryDto>("POST",
    (dto) => ({
        path: "api/skills/categories",
        body: dto
    }));

export const fetchCategories = async (
    query: string,
    accessToken: string | null,
    pageIndex = 0,
    pageSize = 100,
): Promise<SkillCategorySummary[]> => {
    const path = `api/skills/categories?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${query}`;
    const response = await gigApiFetcher<never, GigApiPaginatedData<SkillCategorySummary>>({
        path: path,
        token: accessToken || undefined
    });

    return response.items;
};

export type UseSkillCategoriesReturn =
    Omit<UseGiggedApiSWRReturn<GigApiPaginatedData<SkillCategorySummary>>, "data"> & {
        skillCategories: SkillCategorySummary[]
        orderBy: string
        sortOrder: SortOrder
        pageIndex?: number
        pageSize?: number
        totalCount?: number
        createSkillCategory: (dto: CreateSkillCategoryDto) => Promise<GigApiFetcherResponse<string>>
        setSearchTerm: (searchTerm?: string) => void
        setSortOrder: React.Dispatch<React.SetStateAction<SortOrder>>
        setOrderBy: React.Dispatch<React.SetStateAction<string>>
        setPageIndex: (pageIndex: number) => void
        setPageSize: (pageSize: number) => void
        isCreatingSkillCategory: boolean
    };

export const useSkillCategories = (initialPageSize = 10): UseSkillCategoriesReturn => {
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [orderBy, setOrderBy] = useState("Name");
    const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(initialPageSize);

    const [createSkillCategory, isCreatingSkillCategory] = useCreateSkillCategory();

    const url = "api/skills/categories?" +
        `pageIndex=${pageIndex}&` +
        `pageSize=${pageSize}&` +
        `orderBy=${orderBy}&` +
        `sortOrder=${sortOrder}&` +
        `searchTerm=${searchTerm || ""}`;


    const apiCall = useGiggedApiSWR<GigApiPaginatedData<SkillCategorySummary>>(url);

    return {
        ...apiCall,
        ...apiCall.data,
        skillCategories: apiCall.data?.items || [],
        orderBy,
        sortOrder,
        createSkillCategory: async (dto: CreateSkillCategoryDto) => {
            const response = await createSkillCategory(dto);

            if (response.success) apiCall.mutate();

            return response;
        },
        setSearchTerm,
        setOrderBy,
        setSortOrder,
        setPageIndex,
        setPageSize,
        isCreatingSkillCategory
    };
};