import * as React from "react";
import Menu from "@mui/material/Menu";
import { IconButton } from "@mui/material";

export type IconButtonMenuProps = {
    id: string
    icon: JSX.Element
    children: JSX.Element|JSX.Element[]
}

const IconButtonMenu = ({
    id,
    icon,
    children,
}: IconButtonMenuProps) => {
    const menuId = `${id}-button-menu`;
    const buttonId = `${id}-button-menu-button`;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                id={buttonId}
                aria-controls={open ? menuId : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                {icon}
            </IconButton>
            <Menu
                id={menuId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": buttonId,
                }}
            >
                {children}
            </Menu>
        </>
    );
};

export default IconButtonMenu;
