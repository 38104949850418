import { useFormContext, Controller, FieldError } from "react-hook-form";
import {
    RadioGroup,
    Radio,
    FormLabel,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import FormInputError from "./FormInputError";

export type FormInputRadioOptionProps = {
    value: string;
    label: string;
}

export type FormInputRadioProps = {
    label: string;
    name: string;
    helperText?: string;
    options: FormInputRadioOptionProps[];
    disabled: boolean;
    error?: FieldError
}

function FormInputRadio(props: FormInputRadioProps) {
    const { control } = useFormContext();

    return (
        <>
            <FormControl component="fieldset" fullWidth>
                <FormLabel required sx={{ fontWeight: 700, color: "#0B1B29" }}>{props.label}</FormLabel>
                {
                    props.helperText && <FormHelperText sx={{ marginLeft: 0 }}>{props.helperText}</FormHelperText>
                }
                <Controller
                    name={props.name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <RadioGroup value={value} onChange={onChange}>
                            {props.options.map(option => (
                                <FormControlLabel
                                    key={option.label}
                                    value={option.value}
                                    defaultValue={value}
                                    label={option.label}
                                    disabled={props.disabled}
                                    control={<Radio color="secondary" />}
                                />
                            ))}
                        </RadioGroup>
                    )}
                />
            </FormControl>
            {props.error && <FormInputError error={props.error} /> }
        </>
    );
}

export default FormInputRadio;
